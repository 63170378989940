import * as React from 'react';
import { CSSProperties } from 'react';
import LaddaButton, { SLIDE_UP, XL } from 'react-ladda';
import styled from 'styled-components';
import { TabletV } from '../Breakpoints/Breakpoints';

interface Props {
  style?: CSSProperties;
  className?: string;
  children?: React.ReactChild;
  disabled?: boolean;
  dataCy?: string;
  type?: 'submit' | 'reset' | 'button';
  primary?: boolean;
  invertedPrimary?: boolean;
  green?: boolean;
  invertedGreen?: boolean;
  textTransform?: string;
  small?: boolean;
  loading?: boolean;

  onClick?(): void;
}

function BaseButton(props: Props) {
  const { style, className, children, dataCy, type, onClick, disabled, loading } = props;

  if (loading) {
    return (
      <LaddaButton
        className="laddaButton"
        loading={loading}
        data-color="#eee"
        data-size={XL}
        data-style={SLIDE_UP}
        data-spinner-size={30}
        data-spinner-color="#ddd"
        data-spinner-lines={12}
      >
        <button style={style} className={className} data-cy={dataCy} type={type} onClick={onClick} disabled={disabled}>
          {children}
        </button>
      </LaddaButton>
    )
  }

  return (
    <button style={style} className={className} data-cy={dataCy} type={type} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
}

const Button = styled(BaseButton)`
  font-size: 1em;
  border: 1px solid;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.625em;
  max-width: 15.125rem;
  line-height: inherit;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;

  ${({ small }) => small ? 'max-width: 10rem;' : ''};

  @media (${TabletV}) {
    font-size: 1.1875em;
    padding: 1.25rem 2.5em;
    max-width: 14rem;
  }

  ${props => {
  if (props.primary) {
    return 'color: #fff; background-color: #334d2b; border: 1px solid #334d2b;';
  } else if (props.invertedPrimary) {
    return 'color: #334d2b; background-color: #fff; border: 1px solid #334d2b;';
  } else if (props.green) {
    return 'color: #fff; background-color: #659254; border: 1px solid #659254;';
  } else if (props.invertedGreen) {
    return 'color: #659254; background-color: #eef2ec; border: 1px solid #659254;';
  }
  return '';
}};

  ${props => {
  if (props.textTransform === 'capitalize') {
    return 'text-transform: capitalize';
  }
  return '';
}};

  &:hover {
    ${props => {
  if (props.primary) {
    return 'color: #fff; background-color: #1c3115; border: 1px solid #1c3115;';
  } else if (props.invertedPrimary) {
    return 'color: #334d2b; background-color: #92b784; border: 1px solid #92b784;';
  } else if (props.green) {
    return 'color: #fff; background-color: #334d2b; border: 1px solid #334d2b;';
  } else if (props.invertedGreen) {
    return 'color: #fff; background-color: #334d2b; border: 1px solid #659254;';
  }
  return '';
}};
  }
`;

export default Button;
