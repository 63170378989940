import * as React from 'react';
import { Contact, Logo, Phone, StyledSection, Text } from './Navbar.styles';

interface Props {
  className?: string;
}

const Navbar = (props: Props) => (
  <StyledSection className={props.className}>
    <a href="https://www.under30experiences.com/">
      <Logo src="https://firebasestorage.googleapis.com/v0/b/u30x-prod-205020.appspot.com/o/static%2Fmedia%2Flogo.9ea9d96c.png?alt=media&token=530ef588-7f20-49b3-a9dd-52bb6a074d67" />
    </a>
    <Contact>
      <Phone src={require('../../assets/images/phone.png')} />
      <Text>
        <a href="tel:1-888-839-2086">Contact Us</a>
      </Text>
    </Contact>
  </StyledSection>
);

export default Navbar;
