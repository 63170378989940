export const StripeStyling = {
  base: {
    fontSize: '1.125em',
    color: '#6b6b6b',
    fontSmoothing: 'antialiased',
    padding: '0.875rem .75rem',
    width: '100%',
    border: '1px solid #cfcfcf',
    borderRadius: '3px',
    boxSizing: 'border-box',
    '::placeholder': {
      color: '#6b6b6b'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};
