import { Field, FieldProps, Form, Formik } from 'formik';
import * as R from 'ramda';
import * as React from 'react';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Button, Checkbox, PolicyContext, Text, Title, Title2 } from '../../../../components';
import MobileBottomBar from '../../../../components/MobileBottomBar/MobileBottomBar';
import { useSession } from '../../../../components/Session/Session';
import { goToStep } from '../../../../redux/checkoutStep';
import { updateBooking } from '../../../../redux/createBookingInput';
import { Store } from '../../../../types';
import { ContinueSection, LeftContSection, PoliciesTextArea, TermsTitleSection } from '../../Checkout.styles';
import { INITIAL_VALUES } from '../TravelerInformationStep';
import AdditionalDetailFields from './AdditionalDetailFields';

const validationSchema = Yup.object().shape({
  acceptTerms: Yup.boolean().required('Required'),
  acceptCancellation: Yup.boolean().required('Required'),
  travelers: Yup.array()
    .of(
      Yup.object().shape({
        passport: Yup.object().shape({
          number: Yup.string().notRequired(),
          expiration: Yup.string()
            .matches(/((19|20)\d\d)[-](0[1-9]|1[012])/, {
              message: 'Expiration needs to be in YYYY-MM format',
              excludeEmptyString: true,
            })
            .notRequired(),
          country: Yup.string().notRequired(),
        }).notRequired(),
        address: Yup.object().shape({
          line1: Yup.string().required('Address is required'),
          line2: Yup.string().notRequired(),
          city: Yup.string().required('City is required'),
          region: Yup.string().required('State is required'),
          postalCode: Yup.string().required('Postal code is required'),
          country: Yup.string().required('Country is required'),
        }),
      })
    )
});

const AdditionalDetailsStep = () => {
  const createBookingInput = useSelector((state: Store) => state.createBookingInput);
  const checkoutStep = useSelector((state: Store) => state.checkoutStep);
  const dispatch = useDispatch();
  const { saveSession } = useSession();
  const policy = useContext(PolicyContext);

  return (
    <Formik
      initialValues={R.mergeDeepRight(INITIAL_VALUES, createBookingInput)}
      validationSchema={validationSchema}
      onSubmit={(values: any) => {
        saveSession!(values);
        const { couponId, ...rest } = values; // TODO some reason couponId is being reset here. temporary fix to not clear couponId when updating booking
        dispatch(updateBooking(rest));
        dispatch(goToStep(checkoutStep.step + 1));
        window.scrollTo(0, 0);
      }}
      render={({ values: { acceptTerms, acceptCancellation } }) => (
        <Form
          style={{
            gridArea: 'form'
          }}
          data-cy="travelerInformationStep"
        >
          <LeftContSection
            style={{
              marginBottom: '1rem',
            }}
          >
            <Title primary={true} weight="bold">
              Additional Details
            </Title>

            <AdditionalDetailFields />
          </LeftContSection>

          <LeftContSection>
            <TermsTitleSection>
              <Title primary={true} weight="bold">
                Terms and Conditions
              </Title>

              <Text small={true}>
                Make sure you have read the terms and conditions, before you submit your request.
              </Text>
            </TermsTitleSection>

            <Field
              name="acceptTerms"
              render={({ field, form }: FieldProps<any>) => (
                <Checkbox
                  {...field}
                  onChange={(value: boolean) => form.setFieldValue(field.name, value)}
                  dataCy={field.name}
                  link="https://www.under30experiences.com/terms-of-service"
                  linkText="terms and conditions."
                  label="I have read, understood and accepted the booking"
                />
              )}
            />

            {
              acceptTerms && (
                <>
                  <Title2
                    primary
                    weight="bold"
                  >
                    Transfer and Cancellation Policy
                  </Title2>

                  <PoliciesTextArea dangerouslySetInnerHTML={{ __html: policy }} />

                  <Field
                    name="acceptCancellation"
                    render={({ field, form }: FieldProps<any>) => (
                      <Checkbox
                        {...field}
                        onChange={(value: boolean) => form.setFieldValue(field.name, value)}
                        dataCy={field.name}
                        label="I have read, understood and accepted the cancellation policy."
                      />
                    )}
                  />
                </>
              )
            }

            {
              acceptCancellation && acceptTerms && (
                <ContinueSection>
                  <Button
                    dataCy="additionalDetailsContinueBtn"
                    type="submit"
                    green={true}
                    textTransform="capitalize"
                  >
                    Continue
                  </Button>
                </ContinueSection>
              )
            }
          </LeftContSection>

          <MobileBottomBar />
        </Form>
      )}
    />
  );
};

export default AdditionalDetailsStep;
