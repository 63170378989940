import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import config from './config';

export const cache = new InMemoryCache();

const httpLink = new HttpLink({ uri: config.apiURL });

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

export default apolloClient;
