import { ArrayHelpers, Field, FieldArray, FieldProps, Form, Formik, FormikProps } from 'formik';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  Button,
  FieldTitle,
  InputGroup,
  ReturnCustomerRadioGroup,
  RoomArrangementRadioGroup,
  Text,
  TextField,
  Title,
  Title2,
  TravelerCounter
} from '../../../../components';
import MobileBottomBar from '../../../../components/MobileBottomBar/MobileBottomBar';
import { useSession } from '../../../../components/Session/Session';
import { IRoomArrangement } from '../../../../generated/graphql';
import { goToStep } from '../../../../redux/checkoutStep';
import { updateBooking } from '../../../../redux/createBookingInput';
import { updateTravelerCount } from '../../../../redux/order';
import { Store } from '../../../../types';
import { ContinueSection, FriendName, LeftContSection } from '../../Checkout.styles';
import TravelerFields from './TravelerFields';

export const INITIAL_TRAVELER = {
  firstName: '',
  lastName: '',
  birthday: {
    month: '',
    day: '',
    year: ''
  },
  address: {
    line1: '',
    city: '',
    region: '',
    postalCode: '',
    country: ''
  },
  passport: {
    number: '',
    expiration: '',
    country: ''
  },
  gender: '',
  email: '',
  emailVerification: '',
  phone: ''
};

export const INITIAL_VALUES = {
  returnCustomer: undefined,
  sku: '',
  acceptTerms: false,
  acceptCancellation: false,
  roomArrangement: IRoomArrangement.Solo,
  friendName: '',
  travelers: [INITIAL_TRAVELER],
  cardHolderName: '',
  couponId: ''
};

const validationSchema = Yup.object().shape({
  returnCustomer: Yup.boolean().required('This field is required'),
  roomArrangement: Yup.string().required('Required'),
  friendName: Yup.string().when(['roomArrangement', 'travelers'], {
    is: (roomArrangement, travelers) => roomArrangement === 'friend' && travelers.length === 1,
    then: Yup.string().required('Friend name is required')
  }),
  travelers: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      birthday: Yup.object().shape({
        month: Yup.string()
          .matches(/^[0-9]*$/, 'Month can only contain numbers')
          .required('Month is required'),
        day: Yup.string()
          .matches(/^[0-9]*$/, 'Day can only contain numbers')
          .required('Day is required'),
        year: Yup.string()
          .matches(/^[0-9]*$/, 'Year can only contain numbers')
          .length(4, 'Year must have 4 digits')
          .required('Year is required')
      }),
      gender: Yup.string().required('Gender is required'),
      email: Yup.string()
        .email('Email has wrong format')
        .required('Required'),
      emailVerification: Yup.string()
        .email('Email has wrong format')
        .oneOf([Yup.ref('email')], "Emails don't match")
        .required('Required'),
      phone: Yup.string().required('Phone number is required')
    })
  )
});

const TravelerInformationStep = () => {

  const createBookingInput = useSelector((state: Store) => state.createBookingInput);
  const checkoutStep = useSelector((state: Store) => state.checkoutStep);
  const dispatch = useDispatch();
  const { saveSession } = useSession();

  const initialValues = R.mergeDeepRight(INITIAL_VALUES, createBookingInput);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: any) => {
        saveSession!(values);
        const { couponId, ...rest } = values; // TODO some reason couponId is being reset here. temporary fix to not clear couponId when updating booking
        dispatch(updateBooking(rest));
        values.travelers.forEach((traveler: any) => {
          // @ts-ignore
          klaviyo.identify(
            {
              $email: traveler.email,
              $first_name: traveler.firstName,
              $last_name: traveler.lastName,
              $phone_number: traveler.phone
            },
            () => {
              const event = {
                tripId: createBookingInput.tripId,
                sku: createBookingInput.sku
              };
              // @ts-ignore
              klaviyo.push(['track', 'Checkout started', event]);
            }
          );
        });
        dispatch(goToStep(checkoutStep.step + 1));
        window.scrollTo(0, 0);
      }}
      render={({ setFieldValue, values: { travelers, roomArrangement } }: FormikProps<any>) => (
        <Form
          style={{
            gridArea: 'form'
          }}
          data-cy="travelerInformationStep"
        >
          <LeftContSection>
            <Title primary={true} weight="bold">
              Traveler Information
            </Title>

            <FieldTitle weight="semibold">Have you been on a trip with Under30Experiences before?</FieldTitle>

            <Field
              name="returnCustomer"
              render={({ field }: FieldProps<any>) => <ReturnCustomerRadioGroup {...field} />}
            />

            <FieldTitle weight="semibold">How many travelers?</FieldTitle>

            <Text small={true}>
              Each traveler must be 21 years old by the first day of the trip. **Maximum 2 registrations per checkout.
            </Text>

            <FieldArray
              name="travelers"
              render={(arrayHelpers: ArrayHelpers) => (
                <TravelerCounter
                  min={1}
                  max={2}
                  value={travelers.length}
                  onChange={({ target: { value } }) => {
                    if (value > travelers.length) {
                      arrayHelpers.push(INITIAL_TRAVELER);
                      dispatch(updateTravelerCount(2));
                    } else {
                      arrayHelpers.pop();
                      dispatch(updateTravelerCount(1));
                    }

                    switch (true) {
                      case value === 1 && roomArrangement === 'couple':
                        return setFieldValue('roomArrangement', 'solo');
                      case value > 1 && roomArrangement === 'solo':
                        return setFieldValue('roomArrangement', 'friend');
                    }
                  }}
                />
              )}
            />

            <TravelerFields />

            <Title2 primary={true} weight="bold">
              Room Arrangements/Requests
            </Title2>

            <Text>Please list the name(s) of who you’d like to room with.</Text>

            <br />

            <Text>
              Whenever possible we try to place couples in a private room and friends in the same room. Most rooms are
              double occupancy.
            </Text>

            <br />

            <Text>Question about your room? Let us know below what we can do to make you feel comfortable.</Text>

            <Field
              name="roomArrangement"
              render={({
                field,
                form: {
                  values: { travelers }
                }
              }: FieldProps<any>) => <RoomArrangementRadioGroup {...field} travelerCount={travelers.length} />}
            />

            <Field
              name="friendName"
              render={({
                form: {
                  values: { travelers, roomArrangement }
                }
              }: FieldProps<any>) =>
                travelers.length === 1 &&
                roomArrangement === IRoomArrangement.Friend && (
                  <FriendName>
                    <FieldTitle weight="semibold">Please confirm your roommate's name or names.</FieldTitle>

                    <InputGroup>
                      <Field
                        name="friendName"
                        render={({ field }: FieldProps<any>) => (
                          <TextField {...field} dataCy={field.name} placeholder="Roommate's Name" type="text" />
                        )}
                      />
                    </InputGroup>
                  </FriendName>
                )
              }
            />

            <ContinueSection>
              <Button dataCy="travelerInfoContinueBtn" type="submit" green={true} textTransform="capitalize">
                Continue
              </Button>
            </ContinueSection>
          </LeftContSection>

          <MobileBottomBar />
        </Form>
      )}
    />
  );
};

export default TravelerInformationStep;
