import * as React from 'react';
import { StyledInput, StyledLabel, StyledSpan, StyleRadio } from './Radio.styles';

interface Props {
  className?: string;
  dataCy?: string;
  label?: string;
  name?: string;
  value?: any;
  checked?: boolean;
  required?: boolean;

  onBlur?(e: any): void;

  onChange?(e: any): void;
}

function Radio(props: Props) {
  const { className, dataCy, label, name, value, checked, onBlur } = props;

  const onClick = () => {
    if (props.onChange) {
      props.onChange({
        target: {
          name: props.name,
          value: props.value
        }
      });
    }
  };

  return (
    <StyleRadio className={className}>
      <StyledLabel className={value === checked ? 'checked' : ''}>
        <StyledInput
          type="radio"
          name={name}
          value={value}
          onChange={props.onChange}
          onBlur={onBlur}
          checked={value === checked}
          readOnly={false}
        />
        <StyledSpan data-cy={dataCy} onBlur={onBlur} onClick={onClick}>
          {label}
        </StyledSpan>
      </StyledLabel>
    </StyleRadio>
  );
}

export default Radio;
