import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CouponForm, Map, Title } from '..';
import { ILocation, IOrderItemPreview, useListPaymentOptionsQuery } from '../../generated/graphql';
import currencyFormatter from '../../lib/currencyFormatter';
import {
  convertDollars,
  findDepositSKU,
  findDiscountItem,
  findEarlySKU,
  findFullSKU,
  findSelectedTrip,
  findSelectedTripSKU,
  renderNumberOfAdults,
} from '../../lib/utils';
import { removeCoupon } from '../../redux/createBookingInput';
import { Store } from '../../types';
import Text from '../Text/Text';
import { Image, Info, List, Total } from './CheckoutSidebar.styles';
import DatesSection from './components/DatesSection';
import Loading from './components/Loading';
import Section from './components/Section';

interface Props {
  location: ILocation;
}

const CheckoutSidebar = (props: Props) => {
  const { location } = props;
  const createBookingInput = useSelector((state: Store) => state.createBookingInput);
  const order = useSelector((state: Store) => state.order);
  const dispatch = useDispatch();

  const { tripId, sku, couponId } = createBookingInput;
  const { orderPreview = { amount: 0, items: [] }, orderPreviewLoading, travelerCount } = order;
  const trips = location.trips ?? [];

  const paymentOptionsQuery = useListPaymentOptionsQuery({
    variables: {
      input: {
        couponId,
        quantity: travelerCount,
        sku,
        tripId,
      },
    },
  });

  const selectedTrip = findSelectedTrip(trips, tripId!);
  const selectedTripSKU = findSelectedTripSKU(selectedTrip?.skus, sku!);
  const earlyPriceSKU = findEarlySKU(selectedTrip?.skus);
  const fullPriceSKU = findFullSKU(selectedTrip?.skus);
  const depositPriceSKU = findDepositSKU(selectedTrip?.skus);
  const coupon = findDiscountItem(orderPreview.items!);

  const isEarlySKU = selectedTripSKU?.type === 'early';
  const isDepositSKU = selectedTripSKU?.type === 'deposit';
  const hasCouponAndIsDeposit = isDepositSKU && !!coupon;

  const laterPrice = convertDollars(paymentOptionsQuery.data?.paymentOptions?.find(R.propEq('type', 'deposit'))?.laterPrice ?? 0);
  const earlySignUpDiscountPrice = convertDollars(-1 * (fullPriceSKU.price - earlyPriceSKU.price) * travelerCount);
  const itemPrice = convertDollars((isDepositSKU ? depositPriceSKU.price : fullPriceSKU.price));
  const subtotalPrice = itemPrice * travelerCount;
  const totalPrice = convertDollars(orderPreview.amount!);

  const formatted = {
    laterPrice: currencyFormatter.format(laterPrice),
    earlySignUpDiscount: currencyFormatter.format(earlySignUpDiscountPrice),
    itemPrice: currencyFormatter.format(itemPrice),
    subtotalPrice: currencyFormatter.format(subtotalPrice),
    totalPrice: currencyFormatter.format(totalPrice),
  };

  const renderCoupons = (items: Array<IOrderItemPreview> = []) => {
    const coupons = items
      .filter(({ type }) => type === 'discount')
      .map((item, key) => (
        <Section
          key={key}
          name={`Coupon: ${item.parent!}`}
          amount={currencyFormatter.format(convertDollars(isDepositSKU ? 0 : item.amount!))}
          renderAdditional={!item.global &&
          <button disabled={orderPreviewLoading} onClick={() => dispatch(removeCoupon())}>Remove</button>}
        />
      ));

    if (!coupons.length) {
      return null;
    }

    return <>{coupons}</>;
  };

  return (
    <section
      style={{ background: '#fff' }}
      data-cy="checkoutSidebar"
    >
      <Image src={location.image!} />

      <Info>
        <Title>Trip Summary</Title>

        <br />

        <section>
          <Text variant="strong">{location.title!}</Text>

          <br />

          <Text>{location.description!}</Text>
        </section>

        <br />

        <section>
          <Text variant="strong">Included</Text>

          <br />

          <List>
            <Map
              data={location.included!}
              render={(text: string, index: number) => <li><Text key={index}>{text}</Text></li>}
            />
          </List>
        </section>

        <br />

        <section>
          <Text variant="strong">Not Included</Text>

          <br />

          <List>
            <Map
              data={location.notIncluded!}
              render={(text: string, index: number) => <li><Text key={index}>{text}</Text></li>}
            />
          </List>
        </section>

        <br />

        <Text>
          *Please wait to book your flight until you receive an email from us with more details.
        </Text>
      </Info>

      <Info>
        <DatesSection
          startDate={selectedTrip.startDate}
          endDate={selectedTrip.endDate}
        />
      </Info>

      <Info>
        <section
          style={{
            display: 'grid',
            gridGap: '1rem',
          }}
        >
          <Section
            name={renderNumberOfAdults(travelerCount)}
            amount={`${formatted.itemPrice} x ${travelerCount}`}
          />

          <Section
            name="Subtotal"
            amount={formatted.subtotalPrice}
          />

          {isEarlySKU && (
            <Section
              name="Early Sign Up Discount"
              amount={formatted.earlySignUpDiscount}
            />
          )}

          {renderCoupons(orderPreview!.items!)}

          <CouponForm disabled={orderPreviewLoading} />

          {hasCouponAndIsDeposit && <Text>**Coupon will be applied to your second installment**</Text>}

          <section>
            <Loading loading={orderPreviewLoading}>
              <Section
                name="Total"
                amount={formatted.totalPrice}
                AmountComponent={Total}
              />
            </Loading>
          </section>
        </section>
      </Info>

      {isDepositSKU && (
        <Info>
          <Text>The remaining balance of {formatted.laterPrice} is due 60 days before your trip. You will
            receive an invoice via email.</Text>
        </Info>
      )}
    </section>
  );
};

export default CheckoutSidebar;
