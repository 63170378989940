import * as React from 'react';
import { InputCount, InputCounter, StyledTravelerCounter } from './TravelerCounter.styles';

interface Props {
  name?: string;
  value?: number;
  min?: number;
  max?: number;

  onChange?(e: any): void;
}

function TravelerCounter(props: Props) {
  const { value, min, max } = props;
  const [count, setCount] = (React as any).useState(value);

  const increment = () => {
    const newValue = count + 1;
    setCount(newValue);
    const event = {
      target: {
        name: props.name,
        value: newValue
      }
    };
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const decrement = () => {
    const newValue = count - 1;
    setCount(newValue);
    const event = {
      target: {
        name: props.name,
        value: newValue
      }
    };
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <StyledTravelerCounter>
      <InputCounter
        data-cy="travelerCounterMinus"
        type="button"
        value="-"
        onClick={decrement}
        disabled={!!min && count <= min}
      />
      <InputCount>
        <p>{count}</p>
      </InputCount>
      <InputCounter
        data-cy="travelerCounterPlus"
        type="button"
        value="+"
        id="plus"
        onClick={increment}
        disabled={!!max && count >= max}
      />
    </StyledTravelerCounter>
  );
}

export default TravelerCounter;
