import { Field, FieldProps } from 'formik';
import * as React from 'react';
import {
  FieldTitle,
  GenderRadioGroup,
  InputGroup2,
  InputGroup3,
  Map,
  Text,
  TextField,
  Title2
} from '../../../../components';

const TravelerFields = () => {
  return (
    <Field
      name="travelers"
      render={({ field }: FieldProps) => (
        <Map
          data={field.value}
          render={(traveler: any, i: number) => (
            <section key={i}>
              <Title2 primary={true} weight="bold">
                {`Traveler ${i + 1}`}
              </Title2>

              <InputGroup2>
                <Field
                  name={`travelers[${i}].firstName`}
                  render={({ field }: FieldProps<any>) => (
                    <TextField
                      {...field}
                      dataCy={field.name}
                      placeholder="First name"
                      type="text"
                    />
                  )}
                />

                <Field
                  name={`travelers[${i}].lastName`}
                  render={({ field }: FieldProps<any>) => (
                    <TextField
                      {...field}
                      dataCy={field.name}
                      placeholder="Last name"
                      type="text"
                    />
                  )}
                />
              </InputGroup2>

              <FieldTitle weight="semibold">
                Birthday
              </FieldTitle>

              <InputGroup3>
                <Field
                  name={`travelers[${i}].birthday.month`}
                  render={({ field }: FieldProps<any>) => (
                    <TextField
                      {...field}
                      dataCy={field.name}
                      placeholder="Month (MM)"
                      type="text"
                      maxLength={2}
                    />
                  )}
                />

                <Field
                  name={`travelers[${i}].birthday.day`}
                  render={({ field }: FieldProps<any>) => (
                    <TextField
                      {...field}
                      dataCy={field.name}
                      placeholder="Day (DD)"
                      type="text"
                      maxLength={2}
                    />
                  )}
                />

                <Field
                  name={`travelers[${i}].birthday.year`}
                  render={({ field }: FieldProps<any>) => (
                    <TextField
                      {...field}
                      dataCy={field.name}
                      placeholder="Year (YYYY)"
                      type="text"
                      maxLength={4}
                    />
                  )}
                />
              </InputGroup3>

              <FieldTitle weight="semibold">
                What gender do you identify with?
              </FieldTitle>

              <Text small={true}>Under30Experiences is a community supportive of all gender identities.</Text>

              <Field
                name={`travelers[${i}].gender`}
                render={({ field }: FieldProps<any>) => (
                  <GenderRadioGroup {...field} />
                )}
              />

              <section>
                <Title2 primary={true} weight="bold">
                  Contact Details
                </Title2>

                <InputGroup2>
                  <Field
                    name={`travelers[${i}].email`}
                    render={({ field }: FieldProps<any>) => (
                      <TextField
                        {...field}
                        dataCy={field.name}
                        placeholder="Email"
                        type="email"
                      />
                    )}
                  />

                  <Field
                    name={`travelers[${i}].emailVerification`}
                    render={({ field }: FieldProps<any>) => (
                      <TextField
                        {...field}
                        dataCy={field.name}
                        placeholder="Retype Email"
                        type="email"
                      />
                    )}
                  />
                </InputGroup2>

                <InputGroup2>
                  <Field
                    name={`travelers[${i}].phone`}
                    render={({ field }: FieldProps<any>) => (
                      <TextField
                        {...field}
                        dataCy={field.name}
                        placeholder="Phone"
                        type="tel"
                      />
                    )}
                  />
                </InputGroup2>
              </section>
            </section>
          )}
        />
      )}
    />
  );
};

export default TravelerFields;
