import { ErrorMessage } from 'formik';
import * as React from 'react';
import { Map } from '..';
import { IPaymentOption } from '../../generated/graphql';
import { RadioStyledBoxes } from '../../routes/Checkout/Checkout.styles';
import RadioStyledBox from '../RadioStyledBox/RadioStyledBox';

interface Props {
  name: string;
  value?: any;
  options?: Array<IPaymentOption>;

  onChange?(e: any): void;
}

function SKURadioGroup(props: Props) {
  const { name, options = [], value, onChange } = props;

  return (
    <section>
      <RadioStyledBoxes>
        <Map
          data={options}
          render={(option: IPaymentOption) => (
            <RadioStyledBox
              key={option.sku!}
              name={name}
              label={option.text!}
              text={option.description!}
              value={option.sku!}
              type={option.type!}
              currentValue={value}
              onChange={onChange}
            />
          )}
        />
      </RadioStyledBoxes>

      <ErrorMessage
        name={name}
        render={message => <p style={{ color: 'red', marginTop: '1rem' }}>{message}</p>}
      />
    </section>
  );
}

export default SKURadioGroup;
