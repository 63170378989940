import { ErrorMessage } from 'formik';
import * as React from 'react';
import CoupleImage from '../../assets/images/couple.png';
import SoloImage from '../../assets/images/solo.png';
import FriendImage from '../../assets/images/with-friend.png';
import { IRoomArrangement } from '../../generated/graphql';
import { RadioStyledBoxes } from '../../routes/Checkout/Checkout.styles';
import RadioStyledBox from '../RadioStyledBox/RadioStyledBox';
import { Wrap } from './RoomArrangementRadioGroup.styles';

interface Props {
  name: string;
  value?: any;
  travelerCount: number;

  onChange?(e: any): void;
}

const RoomArrangementRadioGroup = (props: Props) => {
  const { name, value, travelerCount, onChange } = props;

  return (
    <Wrap>
      <RadioStyledBoxes>
        {
          travelerCount === 1 && (
            <RadioStyledBox
              name={name}
              image={SoloImage}
              dataCy="roomArrangement.solo"
              label="Solo"
              text="Own bed in shared room"
              type={IRoomArrangement.Solo}
              value={IRoomArrangement.Solo}
              currentValue={value}
              onChange={onChange}
            />
          )
        }
        <RadioStyledBox
          name={name}
          image={FriendImage}
          dataCy="roomArrangement.withFriend"
          label="With a Friend"
          text="Separate Beds"
          type={IRoomArrangement.Friend}
          value={IRoomArrangement.Friend}
          currentValue={value}
          onChange={onChange}
        />
        {
          travelerCount > 1 && (
            <RadioStyledBox
              name={name}
              dataCy="roomArrangement.asCouple"
              image={CoupleImage}
              label="As a Couple"
              text="Private Room"
              type={IRoomArrangement.Couple}
              value={IRoomArrangement.Couple}
              currentValue={value}
              onChange={onChange}
            />
          )
        }
      </RadioStyledBoxes>
      <ErrorMessage
        name={name}
        render={message => <p style={{ color: 'red', marginTop: '1rem' }}>{message}</p>}
      />
    </Wrap>
  )
};

export default RoomArrangementRadioGroup;
