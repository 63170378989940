import * as React from 'react';
import styled from 'styled-components';
import Text from '../../components/Text/Text';
import { Box, IMG, Label } from './RadioStyledBox.styles';

interface Props {
  dataCy?: string;
  label?: string;
  text?: string;
  image?: string;
  large?: boolean;
  type?: any;
  currentValue?: any;
  name: string;
  value: any;

  onBlur?(e: any): void;

  onChange?(e: any): void;
}

function BaseRadioStyledBox(props: Props) {
  const { dataCy, label, text, image, value, currentValue, onBlur } = props;

  const onClick = () => {
    if (props.onChange) {
      props.onChange({
        target: {
          name: props.name,
          value: props.value,
        },
      });
    }
  };

  return (
    <Box
      className={currentValue === value ? 'active' : ''}
      data-cy={dataCy}
      onBlur={onBlur}
      onClick={onClick}
    >
      {image && <IMG src={image} />}
      <Label>{label}</Label>
      {text && <Text>{text}</Text>}
    </Box>
  );
}

const RadioStyledBox = styled(BaseRadioStyledBox)`
  ${({ large }) => large ? 'max-width: 16.1875rem;' : ''};
`;

export default RadioStyledBox;
