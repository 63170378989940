import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
};

export type IAddress = {
  line1?: Maybe<Scalars['String']>,
  line2?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  region?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
};

export type IAddressInput = {
  line1: Scalars['String'],
  line2?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  region: Scalars['String'],
  postalCode: Scalars['String'],
  country: Scalars['String'],
};

export type IApplyCouponInput = {
  couponId: Scalars['String'],
  sku: Scalars['String'],
};

export type IApplyCouponResponse = {
  discountAmount?: Maybe<Scalars['Float']>,
};

export type IBooking = {
  bookingId?: Maybe<Scalars['ID']>,
  tripId?: Maybe<Scalars['ID']>,
  returnCustomer?: Maybe<Scalars['Boolean']>,
  roomArrangement?: Maybe<IRoomArrangement>,
  friendName?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  travelers?: Maybe<Array<ITraveler>>,
  referralCandySignature?: Maybe<Scalars['String']>,
  trip?: Maybe<ITrip>,
  order?: Maybe<IBookingOrder>,
};

export type IBookingOrder = {
  bookingId?: Maybe<Scalars['ID']>,
  orderId?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Float']>,
  amountReturned?: Maybe<Scalars['Float']>,
  charge?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  couponId?: Maybe<Scalars['String']>,
  livemode?: Maybe<Scalars['Boolean']>,
  status?: Maybe<Scalars['String']>,
  sku?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  couponIds?: Maybe<Array<Scalars['ID']>>,
  type?: Maybe<Scalars['String']>,
};

export type ICountry = {
  code?: Maybe<Scalars['String']>,
  alpha2?: Maybe<Scalars['String']>,
  alpha3?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  region?: Maybe<Scalars['String']>,
  subRegion?: Maybe<Scalars['String']>,
  intermediateRegion?: Maybe<Scalars['String']>,
  countryCode?: Maybe<Scalars['String']>,
  regionCode?: Maybe<Scalars['String']>,
  subRegionCode?: Maybe<Scalars['String']>,
  intermediateRegionCode?: Maybe<Scalars['String']>,
};

export type ICreateBookingInput = {
  tripId: Scalars['String'],
  returnCustomer: Scalars['Boolean'],
  travelers: Array<ITravelerInput>,
  roomArrangement: IRoomArrangement,
  friendName?: Maybe<Scalars['String']>,
  acceptTerms?: Maybe<Scalars['Boolean']>,
  acceptCancellation?: Maybe<Scalars['Boolean']>,
  token: Scalars['String'],
  sku: Scalars['String'],
  couponId?: Maybe<Scalars['String']>,
  referralCode?: Maybe<Scalars['String']>,
  idempotencyKey?: Maybe<Scalars['String']>,
  sessionId?: Maybe<Scalars['String']>,
};

export enum IGender {
  Male = 'male',
  Female = 'female'
}

export type IGetOrderPreviewInput = {
  quantity: Scalars['Int'],
  sku: Scalars['ID'],
  couponId?: Maybe<Scalars['ID']>,
};

export type ILocation = {
  locationId: Scalars['ID'],
  country?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['String']>,
  included?: Maybe<Array<Scalars['String']>>,
  notIncluded?: Maybe<Array<Scalars['String']>>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  cntry?: Maybe<ICountry>,
  trips?: Maybe<Array<ITrip>>,
};


export type ILocationTripsArgs = {
  filter?: Maybe<ITripsFilter>
};

export type IMutation = {
  createBooking?: Maybe<IBooking>,
  applyCoupon?: Maybe<IApplyCouponResponse>,
  getOrderPreview?: Maybe<IOrderPreview>,
  saveSession?: Maybe<Scalars['Boolean']>,
};


export type IMutationCreateBookingArgs = {
  input?: Maybe<ICreateBookingInput>
};


export type IMutationApplyCouponArgs = {
  input: IApplyCouponInput
};


export type IMutationGetOrderPreviewArgs = {
  input: IGetOrderPreviewInput
};


export type IMutationSaveSessionArgs = {
  id: Scalars['ID'],
  session: Scalars['String']
};

export type IOrderItemPreview = {
  type?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  parent?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  quantity?: Maybe<Scalars['Int']>,
  global?: Maybe<Scalars['Boolean']>,
};

export type IOrderPreview = {
  amount?: Maybe<Scalars['Int']>,
  items?: Maybe<Array<IOrderItemPreview>>,
};

export type IPassport = {
  number?: Maybe<Scalars['String']>,
  expiration?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
};

export type IPassportInput = {
  number?: Maybe<Scalars['String']>,
  expiration?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
};

export type IPaymentOption = {
  sku?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Int']>,
  laterPrice?: Maybe<Scalars['Int']>,
  text?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export type IPaymentOptionsInput = {
  tripId: Scalars['ID'],
  sku: Scalars['ID'],
  quantity: Scalars['Int'],
  couponId?: Maybe<Scalars['ID']>,
};

export type IQuery = {
  getBooking?: Maybe<IBooking>,
  getTripBookingsCount?: Maybe<Scalars['Int']>,
  getTripTravelersCount?: Maybe<Scalars['Int']>,
  listLocations?: Maybe<Array<ILocation>>,
  getLocation?: Maybe<ILocation>,
  listTripSKUs?: Maybe<Array<ITripSku>>,
  getBookingOrder?: Maybe<IBookingOrder>,
  listOrderCoupons?: Maybe<Array<Scalars['ID']>>,
  skuType: Scalars['String'],
  paymentOptions?: Maybe<Array<IPaymentOption>>,
  listTrips?: Maybe<Array<Maybe<ITrip>>>,
  trips?: Maybe<Array<Maybe<ITrip>>>,
  getTrip?: Maybe<ITrip>,
  policy: Scalars['String'],
};


export type IQueryGetBookingArgs = {
  bookingId: Scalars['ID']
};


export type IQueryGetTripBookingsCountArgs = {
  tripId: Scalars['ID']
};


export type IQueryGetTripTravelersCountArgs = {
  tripId: Scalars['ID']
};


export type IQueryGetLocationArgs = {
  locationId: Scalars['ID']
};


export type IQueryListTripSkUsArgs = {
  tripId: Scalars['ID']
};


export type IQueryGetBookingOrderArgs = {
  bookingId: Scalars['ID']
};


export type IQueryListOrderCouponsArgs = {
  orderId: Scalars['ID']
};


export type IQuerySkuTypeArgs = {
  sku: Scalars['String']
};


export type IQueryPaymentOptionsArgs = {
  input: IPaymentOptionsInput
};


export type IQueryListTripsArgs = {
  locationId?: Maybe<Scalars['ID']>,
  active?: Maybe<Scalars['Boolean']>
};


export type IQueryTripsArgs = {
  filter?: Maybe<ITripsFilter>
};


export type IQueryGetTripArgs = {
  tripId: Scalars['ID']
};

export enum IRoomArrangement {
  Solo = 'solo',
  Friend = 'friend',
  Couple = 'couple'
}

export type ITraveler = {
  travelerId?: Maybe<Scalars['ID']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  birthday?: Maybe<Scalars['String']>,
  gender?: Maybe<IGender>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  passport?: Maybe<IPassport>,
  address?: Maybe<IAddress>,
};

export type ITravelerInput = {
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  birthday: Scalars['String'],
  gender: IGender,
  email: Scalars['String'],
  phone: Scalars['String'],
  passport?: Maybe<IPassportInput>,
  address: IAddressInput,
};

export type ITrip = {
  tripId?: Maybe<Scalars['ID']>,
  locationId?: Maybe<Scalars['ID']>,
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  maxSeats?: Maybe<Scalars['Int']>,
  detailsUrl?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Boolean']>,
  archived?: Maybe<Scalars['Boolean']>,
  tags?: Maybe<Array<Scalars['String']>>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  location?: Maybe<ILocation>,
  skus?: Maybe<Array<ITripSku>>,
  productId?: Maybe<Scalars['ID']>,
  seatsTaken?: Maybe<Scalars['Int']>,
};

export type ITripsFilter = {
  locationId?: Maybe<Scalars['ID']>,
  archived?: Maybe<Scalars['Boolean']>,
  disabled?: Maybe<Scalars['Boolean']>,
};

export type ITripSku = {
  tripId?: Maybe<Scalars['ID']>,
  sku?: Maybe<Scalars['ID']>,
  type?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Int']>,
  active?: Maybe<Scalars['Boolean']>,
  livemode?: Maybe<Scalars['Boolean']>,
  currency?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
};

export type IGetLocationQueryVariables = {
  locationId: Scalars['ID']
};


export type IGetLocationQuery = (
  Pick<IQuery, 'policy'>
  & { getLocation: Maybe<(
    Pick<ILocation, 'locationId' | 'country' | 'title' | 'description' | 'included' | 'notIncluded' | 'image' | 'createTime' | 'updateTime'>
    & { cntry: Maybe<Pick<ICountry, 'name' | 'region' | 'subRegion' | 'intermediateRegion'>>, trips: Maybe<Array<(
      Pick<ITrip, 'tripId' | 'locationId' | 'startDate' | 'endDate' | 'maxSeats' | 'seatsTaken' | 'detailsUrl' | 'tags' | 'createTime' | 'updateTime'>
      & { skus: Maybe<Array<Pick<ITripSku, 'tripId' | 'sku' | 'type' | 'price' | 'active' | 'livemode' | 'currency' | 'createTime' | 'updateTime'>>> }
    )>> }
  )> }
);

export type ISaveSessionMutationVariables = {
  id: Scalars['ID'],
  session: Scalars['String']
};


export type ISaveSessionMutation = Pick<IMutation, 'saveSession'>;

export type IListPaymentOptionsQueryVariables = {
  input: IPaymentOptionsInput
};


export type IListPaymentOptionsQuery = { paymentOptions: Maybe<Array<Pick<IPaymentOption, 'sku' | 'type' | 'price' | 'laterPrice' | 'text' | 'description'>>> };

export type ICreateBookingMutationVariables = {
  input: ICreateBookingInput
};


export type ICreateBookingMutation = { createBooking: Maybe<(
    Pick<IBooking, 'bookingId'>
    & { trip: Maybe<Pick<ITrip, 'tripId' | 'locationId'>> }
  )> };


export const GetLocationDocument = gql`
    query getLocation($locationId: ID!) {
  getLocation(locationId: $locationId) {
    locationId
    country
    cntry {
      name
      region
      subRegion
      intermediateRegion
    }
    title
    description
    included
    notIncluded
    image
    createTime
    updateTime
    trips {
      tripId
      locationId
      startDate
      endDate
      maxSeats
      seatsTaken
      detailsUrl
      tags
      createTime
      updateTime
      skus {
        tripId
        sku
        type
        price
        active
        livemode
        currency
        createTime
        updateTime
      }
    }
  }
  policy
}
    `;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetLocationQuery, IGetLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetLocationQuery, IGetLocationQueryVariables>(GetLocationDocument, baseOptions);
      }
export function useGetLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetLocationQuery, IGetLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetLocationQuery, IGetLocationQueryVariables>(GetLocationDocument, baseOptions);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = ApolloReactCommon.QueryResult<IGetLocationQuery, IGetLocationQueryVariables>;
export const SaveSessionDocument = gql`
    mutation saveSession($id: ID!, $session: String!) {
  saveSession(id: $id, session: $session)
}
    `;
export type ISaveSessionMutationFn = ApolloReactCommon.MutationFunction<ISaveSessionMutation, ISaveSessionMutationVariables>;

/**
 * __useSaveSessionMutation__
 *
 * To run a mutation, you first call `useSaveSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSessionMutation, { data, loading, error }] = useSaveSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      session: // value for 'session'
 *   },
 * });
 */
export function useSaveSessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISaveSessionMutation, ISaveSessionMutationVariables>) {
        return ApolloReactHooks.useMutation<ISaveSessionMutation, ISaveSessionMutationVariables>(SaveSessionDocument, baseOptions);
      }
export type SaveSessionMutationHookResult = ReturnType<typeof useSaveSessionMutation>;
export type SaveSessionMutationResult = ApolloReactCommon.MutationResult<ISaveSessionMutation>;
export type SaveSessionMutationOptions = ApolloReactCommon.BaseMutationOptions<ISaveSessionMutation, ISaveSessionMutationVariables>;
export const ListPaymentOptionsDocument = gql`
    query ListPaymentOptions($input: PaymentOptionsInput!) {
  paymentOptions(input: $input) {
    sku
    type
    price
    laterPrice
    text
    description
  }
}
    `;

/**
 * __useListPaymentOptionsQuery__
 *
 * To run a query within a React component, call `useListPaymentOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPaymentOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPaymentOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListPaymentOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListPaymentOptionsQuery, IListPaymentOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<IListPaymentOptionsQuery, IListPaymentOptionsQueryVariables>(ListPaymentOptionsDocument, baseOptions);
      }
export function useListPaymentOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListPaymentOptionsQuery, IListPaymentOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListPaymentOptionsQuery, IListPaymentOptionsQueryVariables>(ListPaymentOptionsDocument, baseOptions);
        }
export type ListPaymentOptionsQueryHookResult = ReturnType<typeof useListPaymentOptionsQuery>;
export type ListPaymentOptionsLazyQueryHookResult = ReturnType<typeof useListPaymentOptionsLazyQuery>;
export type ListPaymentOptionsQueryResult = ApolloReactCommon.QueryResult<IListPaymentOptionsQuery, IListPaymentOptionsQueryVariables>;
export const CreateBookingDocument = gql`
    mutation createBooking($input: CreateBookingInput!) {
  createBooking(input: $input) {
    bookingId
    trip {
      tripId
      locationId
    }
  }
}
    `;
export type ICreateBookingMutationFn = ApolloReactCommon.MutationFunction<ICreateBookingMutation, ICreateBookingMutationVariables>;

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateBookingMutation, ICreateBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateBookingMutation, ICreateBookingMutationVariables>(CreateBookingDocument, baseOptions);
      }
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = ApolloReactCommon.MutationResult<ICreateBookingMutation>;
export type CreateBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateBookingMutation, ICreateBookingMutationVariables>;