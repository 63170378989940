import styled from 'styled-components';
import { Desktop, Laptop } from '../Breakpoints/Breakpoints';
import { Title4, Title5 } from '../Title/Title';

export const StyledSection = styled.section`
  background: #fff;
  padding: 2.625rem 2.5rem 2.5rem;
  display: none;

  @media (${Laptop}) {
    display: block;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.875rem;

  &:last-child {
    padding-bottom: 0;
  }

  img {
    padding-right: 1rem;
  }
`;

export const StyledTitle4 = styled(Title4)`
  padding-bottom: 1.25rem;

  @media (${Laptop}) {
    font-size: 1.5em;
  }

  @media (${Desktop}) {
    font-size: 1.75em;
  }
`;

export const StyledTitle5 = styled(Title5)`
  color: #6b6b6b;

  @media (${Laptop}) {
    font-size: 1.125em;
  }

  @media (${Desktop}) {
    font-size: 1.4375em;
  }

  * {
    color: inherit;
    text-decoration: none;
  }
`;
