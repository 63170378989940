import styled from 'styled-components';
import { Desktop, Laptop, TabletH, TabletV } from '../Breakpoints/Breakpoints';
import Text from '../Text/Text';
import { Title3 } from '../Title/Title';

export const StyledSection = styled.section`
  background: #eef2ec;
  margin-top: 1rem;

  @media (${Laptop}) {
    margin-bottom: 1rem;
  }
`;

export const TravelerInfo = styled.div`
  padding: 1.5rem 1.25rem;
  border-bottom: 1px solid #dbdbdb;
  &:last-child {
    border-bottom: 1px solid #dbdbdb;
  }

  @media (${TabletV}) {
    padding: 1.75rem 2.5rem;
  }

  @media (${Laptop}) {
    padding: 2.125rem 2rem;
  }

  @media (${Desktop}) {
    padding: 2.375rem 2.25rem;
  }
`;

export const TravelerInfoTitle = styled(Title3)`
  color: #2f4427;
  padding-bottom: 0.5rem;
`;

export const TravelerDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: initial;
  justify-content: space-between;

  > *:not(:last-child) {
    padding-bottom: 0.625rem;
  }

  @media (${Desktop}) {
    flex-direction: row;

    > *:not(:last-child) {
      padding-right: 4.375rem;
    }

    > * {
      padding-bottom: 0 !important;
    }
  }
`;

export const TravelerDetailsFull = styled(TravelerDetails)``;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;

  @media (${Desktop}) {
    flex: 1;
  }
`;

export const List = styled.div`
  flex: 1;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 0.75rem;

  > *:not(:last-child) {
    padding-right: 0.25rem;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .black {
    color: #000;
  }

  @media (${TabletV}) {
    flex-direction: row;
    padding-bottom: 0.625rem;

    > * {
      padding-bottom: 0 !important;
    }
  }
`;

export const ListItemFull = styled(ListItem)``;

export const StyledText = styled(Text)`
  flex: 3;
  color: #6b6b6b;
  word-break: break-all;

  @media (${TabletH}) {
    flex: 5;
  }

  @media (${Laptop}) {
    flex: 4;
  }
`;

export const BlackText = styled(Text)`
  flex: 2;
  color: #000;
`;
