import styled from 'styled-components';
import { Laptop, TabletH, TabletV } from '../Breakpoints/Breakpoints';
import Text from '../Text/Text';

export const StyledSection = styled.section`
  background: #e2eddc;
  padding: 0 1rem 5.5rem;
  text-align: center;

  @media (${TabletV}) {
    padding: 0 1rem 7rem;
  }

  @media (${Laptop}) {
    padding: 0 1rem 2.5rem;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5rem;

  @media (${TabletV}) {
    padding-bottom: 1.125rem;
  }

  @media (${TabletH}) {
    padding-bottom: 1.375rem;
  }

  @media (${Laptop}) {
    padding-bottom: 1.5625rem;
  }
`;

export const StyledText = styled(Text)`
  font-size: 0.6875rem;
  color: #2f4427;

  a {
    color: #2f4427;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
