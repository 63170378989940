import { DateTime } from 'luxon';
import * as R from 'ramda';
import { ILocation, IOrderItemPreview, ITrip, ITripSku } from '../generated/graphql';
import { isSoldOut } from './trip';

export const renderNumberOfAdults = (count: number) => `${count} Adult${count !== 1 ? 's' : ''}`;

export const findSelectedTrip = (trips: Array<ITrip>, tripId?: string) => R.find(R.propEq('tripId', tripId))(trips);

export const findSelectedTripSKU = (skus: Array<ITripSku>, sku: string) => R.find(R.propEq('sku', sku))(skus);

export const findEarlySKU = (skus: Array<ITripSku>) => R.find(R.propEq('type', 'early'))(skus);

export const findFullSKU = (skus: Array<ITripSku>) => R.find(R.propEq('type', 'full'))(skus);

export const findDepositSKU = (skus: Array<ITripSku>) => R.find(R.propEq('type', 'deposit'))(skus);

export const findDeposit = R.find(R.propEq('type', 'deposit'));

export const findSecondInstallmentSKU = (skus: Array<ITripSku>) => R.find(R.propEq('type', 'second_installment'))(skus);

export const findDiscountItem = (items: Array<IOrderItemPreview>) => R.find(R.propEq('type', 'discount'))(items);

export const findDiscountItems = (items: Array<IOrderItemPreview>) => R.filter(R.propEq('type', 'discount'))(items);

export const getDiscountAmountFromCoupons = (coupons: Array<any>) => coupons.map((coupon: any) => coupon.amount).reduce(R.add, 0);

export const convertDollars = R.divide(R.__, 100);

export const convertCents = R.multiply(100);

export const getSeatsLeftText = (trip: ITrip, seatsLeft: number) => {
  switch (true) {
    case isSoldOut(trip):
      return 'Sold Out';
    case seatsLeft === 1:
      return `${seatsLeft} Seat Left`;
    case seatsLeft >= 10:
      return '10+ Seats Left';
    default:
      return `${seatsLeft} Seats Left`;
  }
};

export const tripToKey = (trip: ITrip) => DateTime.fromISO(trip.startDate!).toFormat('yyyyMM');

export const pickRegion = (location: ILocation) => {
  switch (location!.cntry!.region) {
    case 'Americas':
      return location!.cntry!.intermediateRegion;
    default:
      return location!.cntry!.region;
  }
};
