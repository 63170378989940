import styled from 'styled-components';
import { Laptop, TabletH, TabletV } from '../Breakpoints/Breakpoints';

export const StyledSection = styled.section`
  display: flex; 
`;

export const Steps = styled.div`
  display: flex;
  align-item: center;
  max-width: 57.5rem;
  margin: 0 auto;
  background: #659254;
  border-radius: 50px;

  @media (${Laptop}) {
    max-width: 62.5rem;
  }
`;

export const Step = styled.p`
  font-size: 0.75em;
  line-height: 1.3;
  color: #659254;
  padding: 0.5rem 2.25rem;
  border-radius: 50px;
  background: #fff;
  margin-left: -1.5rem;
  z-index: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  cursor: pointer;

  @media (${TabletV}) {
    font-size: 1.125em;
    line-height: 1.3;
    padding: 0.5rem 2.75rem;
    margin-left: -1.875rem;
  }

  @media (${TabletH}) {
    padding: 0.5rem 3.75rem;
  }

  &:first-child {
    margin: 0;
  }

  &.current {
    background: #334d2b;
    color: #fff;
    z-index: 2;
    @media (${TabletH}) {
      margin-left: -1.875rem;
    }
  }

  &.previous {
    background: #659254;
    color: #fff;
    margin-left: 0;
    z-index: 1;
    @media (${TabletH}) {
      margin-left: -1.875rem;
    }

    &:nth-child(2) {
      margin-left: -1.875rem;
    }
  }

  .desktop {
    display: none;

    @media (${Laptop}) {
      display: block;
    }
  }

  .tablet {
    display: none;

    @media (${TabletV}) {
      display: block;
    }

    @media (${Laptop}) {
      display: none;
    }
  }

  .mobile {
    @media (${TabletV}) {
      display: none;
    }
  }
`;
