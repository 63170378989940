import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { IOrderPreview } from '../../generated/graphql';
import currencyFormatter from '../../lib/currencyFormatter';
import { getSKUFromOrderPreview, hasDiscounts } from '../../lib/trip';

interface Props {
  loading?: boolean;
  orderPreview?: IOrderPreview;
}

function OrderPreviewPrice({ loading, orderPreview }: Props) {

  if (loading) {
    return <FontAwesomeIcon spin icon={faSpinner} />;
  }

  if (!orderPreview) {
    return null;
  }

  const sku = getSKUFromOrderPreview(orderPreview);

  return (
    <>
      {hasDiscounts(orderPreview) && (
        <span style={{ textDecoration: 'line-through' }}>
          {currencyFormatter.format((sku.amount * sku.quantity) / 100)}
        </span>
      )}
      &nbsp;
      <span>{currencyFormatter.format(orderPreview.amount! / 100)}</span>
    </>
  );
}

export default OrderPreviewPrice;
