import * as React from 'react';
import styled from 'styled-components';
import { Laptop, TabletH, TabletV } from '../Breakpoints/Breakpoints';

export interface Props {
  className?: string;
  label?: string;
  required?: boolean;
  children?: Array<React.ReactElement<any>>;
  style?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-family: Roboto, 'sans-serif';
  font-weight: 700;
  color: #000000;
  margin-bottom: 0.5rem;

  @media (${TabletV}) {
    margin-bottom: 1rem;
  }
`;

const StyledButtonGroup = styled.div``;

export const RadioGroupArea = styled.div`
  padding-top: 0.875rem;
  padding-bottom: 1rem;

  @media (${TabletV}) {
    padding-bottom: 1.375rem;
  }

  @media (${TabletH}) {
    padding-bottom: 1.625rem;
  }

  @media (${Laptop}) {
    padding-bottom: 1.875rem;
  }
`;

class RadioGroup extends React.PureComponent<Props, {}> {
  render() {
    const { className, label, children, required } = this.props;
    return (
      <Container className={className}>
        {!!label && (
          <Label>
            {label} {required ? '*' : ''}
          </Label>
        )}
        <StyledButtonGroup>{children}</StyledButtonGroup>
      </Container>
    );
  }
}

export default RadioGroup;
