import styled from 'styled-components';
import { Desktop, Laptop, TabletH } from '../Breakpoints/Breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 1em;
  font-weight: 700;
  color: #000000;
  margin-bottom: 0.75rem;
`;

export const Input = styled.input`
  font-size: 0.9375em;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 1rem 0.75rem;
  width: auto;
  color: #6b6b6b;
  font-family: 'Raleway', sans-serif;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;

  @media (${TabletH}) {
    font-size: 1em;
    padding: 1rem 0.9375rem;
  }

  @media (${Laptop}) {
    font-size: 1.125em;
    padding: 1.25rem 1rem;
  }

  &:required {
    box-shadow: none;
  }

  &.isStripe {
    font-size: 1.125em !important;
  }
`;

export const Select = styled.select`
  font-size: 0.9375em;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  box-sizing: border-box;
  width: 100%;
  color: #6b6b6b;
  font-family: 'Raleway', sans-serif;
  box-shadow: none;
  height: 3.3125rem;
  background: #fff;
  text-indent: 0.25rem;

  @media (${TabletH}) {
    font-size: 1em;
    text-indent: 0.4375rem;
  }

  @media (${Laptop}) {
    font-size: 1.125em;
    height: 3.9375rem;
    text-indent: 0.5rem;
  }
`;

export const TextArea = Input.withComponent('textarea');

export const StyledTextArea = styled(TextArea)`
  height: 8em;

  @media (${Laptop}) {
    height: 10em;
  }

  @media (${Desktop}) {
    height: 11.5625em;
  }
`;

export const Hint = styled.p`
  font-size: 1.4375em;
  color: #999999;
  margin-top: 1rem;
  margin-bottom: 0;
`;
