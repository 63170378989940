import styled from 'styled-components';
import { Desktop, Laptop, TabletH, TabletV } from '../Breakpoints/Breakpoints';
import Text from '../Text/Text';

export const Accordions = styled.div`
  .Collapsible {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      .Collapsible__trigger {
        background: #2f4427;
        border: 1px solid #2f4427;
        color: #fff;
      }
    }
  }
  .Collapsible__trigger {
    font-size: 1em;
    color: #2f4427;
    background: #eef2ec;
    border: 1px solid #e3e3e3;
    padding: 1.125rem 1rem;
    cursor: pointer;
    display: block;
    position: relative;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;

    &:after {
      position: absolute;
      right: 1rem;
      content: '\f107';
      font-family: FontAwesome;
    }

    &.is-open,
    &:hover {
      background: #2f4427;
      border: 1px solid #2f4427;
      color: #fff;
    }
    &.is-open {
      &:after {
        content: '\f106';
        font-family: FontAwesome;
      }
    }
    &.is-closed + .Collapsible__contentOuter {
      height: 0 !important;
    }

    @media (${TabletV}) {
      font-size: 1.25em;
      padding: 1.25rem 1.125rem;
      &:after {
        right: 1.125rem;
      }
    }
    @media (${TabletH}) {
      font-size: 1.375em;
    }
    @media (${Laptop}) {
      font-size: 1.5625em;
    }
  }
  .Collapsible__contentOuter {
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  padding: 0.625rem 1rem;
  background: #fff;
  cursor: pointer;
  border-bottom: 1px solid #e3e3e3;

  > * {
    margin-bottom: 1rem;

    @media (${TabletV}) {
      padding: 0.625rem 0;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .seatsLeft {
    max-width: 160px;
    color: #000;
    padding: 0.5rem 0;
    margin: 0;
  }

  @media (${TabletV}) {
    flex-direction: row;
    align-items: center;
    padding: 1.375rem 1.125rem;

    .seatsLeft {
      background: #fff;
      color: #000;
      padding: 0.625rem 1.25rem;
      width: 22%;
      text-align: center;
    }
  }

  @media (${TabletH}) {
    .seatsLeft {
      width: 15%;
    }
  }

  &:hover,
  &.active {
    background: #659254;
    p,
    time,
    span,
    div {
      color: #fff;
    }

    @media (${TabletV}) {
      .seatsLeft {
        background: #aaeb6a;
        color: #659254;
      }
    }
  }

  &:hover {
    background: #e2eddc;
    p,
    time,
    span,
    div {
      color: #000;
    }
  }

  &.soldout {
    background: #e5e5e5;
    p,
    time,
    span,
    div {
      color: #8f8f8f !important;
      text-decoration: line-through;
    }
    .seatsLeft {
      background: transparent;
      text-decoration: none;
    }
  }
`;

export const StyledText = styled(Text)`
  font-size: 1em;
  color: #000;
  padding-bottom: 0.625rem;

  @media (${TabletV}) {
    padding: 0;
  }

  @media (${TabletH}) {
    font-size: 1.125em;
  }
`;

export const Price = styled(StyledText)`
  font-size: 1em;
  color: #659254;
  position: absolute;
  bottom: 0.625rem;
  top: initial;
  right: 1rem;

  @media (${TabletV}) {
    position: relative;
    font-size: 1.375em;
    top: initial;
    bottom: initial;
    right: initial;
    width: 15%;
    text-align: right;
  }
`;

export const DateText = styled.div`
  * {
    line-height: 1.3;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 0.95em;
    color: #000;
    padding-bottom: 0.625rem;

    @media (${TabletV}) {
      font-size: 1em;
      padding: 0;
    }

    @media (${TabletH}) {
      padding: 0;
      font-size: 1.125em;
    }
  }
`;

export const Dates = styled(DateText)`
  @media (${TabletV}) {
    width: 35%;
  }

  @media (${TabletH}) {
    width: 50%;
  }

  @media (${Desktop}) {
    width: 40%;
  }
`;


export const DaysLeft = styled(DateText)`
  @media (${TabletV}) {
    width: 28%;
    text-align: center;
  }

  @media (${TabletH}) {
    width: 15%;
  }

  @media (${Laptop}) {
    width: 20%;
  }
`;

export const DateTextDesktop = styled.div`
  display: none;
  @media (${TabletV}) {
    display: block;
  }
`;

export const DateTextMobile = styled.div`
  display: block;
  @media (${TabletV}) {
    display: none;
  }
`;
