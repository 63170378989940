import { ErrorMessage, Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import * as Yup from 'yup';
import { Button, SelectDateAccordions, Text, Title, Title2 } from '../../../../components';
import { useSession } from '../../../../components/Session/Session';
import { ILocation, ITrip } from '../../../../generated/graphql';
import { getBestTripSKU, isSoldOut } from '../../../../lib/trip';
import { findSelectedTrip } from '../../../../lib/utils';
import { goToStep } from '../../../../redux/checkoutStep';
import { updateBooking } from '../../../../redux/createBookingInput';
import { Store } from '../../../../types';
import {
  AngleRight,
  DayItem,
  Days,
  FormLayout,
  SpaceBreak,
  StyledText,
  SummaryContainer,
  SummaryLeft,
  SummaryRight,
  SummaryText,
} from './Location.styles';

const validationSchema = Yup.object().shape({
  tripId: Yup.string().required('Trip is required'),
});

interface Props {
  location: ILocation;
}

const Location = (props: Props) => {
  const { location } = props;
  const createBookingInput = useSelector((state: Store) => state.createBookingInput);
  const checkoutStep = useSelector((state: Store) => state.checkoutStep);
  const dispatch = useDispatch();
  const { saveSession } = useSession();

  const trips = location.trips || [];

  return (
    <Formik
      initialValues={createBookingInput}
      validationSchema={validationSchema}
      onSubmit={(values: any) => {
        saveSession!(values);
        dispatch(updateBooking(values));
        dispatch(goToStep(checkoutStep.step + 1));
        window.scrollTo(0, 0);
      }}
      render={(formikProps) => {
        const selectedTrip = findSelectedTrip(trips, formikProps.values.tripId);

        return (
          <FormLayout data-cy="locationStep">
            <Title style={{ textAlign: 'center' }} primary weight="bold">{`Please Select your ${location.title!} Date`}</Title>

            <br />

            <Field
              name="tripId"
              render={({ field, form }: FieldProps<any>) => (
                <SelectDateAccordions
                  {...field}
                  data={trips}
                  onChange={(trip: ITrip) => {
                    if (!isSoldOut(trip)) {
                      form.setFieldValue(field.name, trip.tripId);
                      const tripSKU = getBestTripSKU(trip);
                      form.setFieldValue('sku', tripSKU!.sku);
                      form.setFieldValue('couponId', '');

                      Scroll.scroller.scrollTo('details', {
                        duration: 500,
                        delay: 100,
                        smooth: true,
                        offset: -60,
                      });
                    }
                  }}
                />
              )}
            />

            <SummaryContainer>
              <SummaryLeft>
                <Scroll.Element name="details">
                  <Title2
                    style={{
                      color: '#2f4427',
                      paddingBottom: '0.625rem',
                    }}
                    primary={true}
                    weight="bold"
                  >
                    {location.title!}
                  </Title2>
                </Scroll.Element>

                <SummaryText>
                  At checkout you will have the option to pay in full or put a deposit down on your trip.{' '}
                  <a className="cancellation" target="_blank" rel="noopener noreferrer" href="https://www.under30experiences.com/terms-of-service">
                    Cancellation policy
                  </a>
                </SummaryText>
              </SummaryLeft>

              {selectedTrip && (
                <SummaryRight data-cy="summary-right">
                  <Days>
                    <DayItem>
                      <Text
                        style={{
                          color: '#000',
                        }}
                        weight="bold"
                      >
                        <Moment format={'dddd'} date={selectedTrip.startDate} />
                      </Text>
                      <StyledText>
                        <Moment format={'D MMMM, YYYY (ddd)'} date={selectedTrip.startDate} />
                      </StyledText>
                    </DayItem>

                    <SpaceBreak>
                      <AngleRight src={require('../../../../assets/images/angle-right.png')} />
                    </SpaceBreak>

                    <DayItem>
                      <Text
                        style={{
                          color: '#000',
                        }}
                        weight="bold"
                      >
                        <Moment format={'dddd'} date={selectedTrip.endDate} />
                      </Text>
                      <StyledText>
                        <Moment format={'D MMMM, YYYY (ddd)'} date={selectedTrip.endDate} />
                      </StyledText>
                    </DayItem>
                  </Days>
                </SummaryRight>
              )}
            </SummaryContainer>

            <section
              style={{
                textAlign: 'right',
                marginTop: '2rem',
              }}
            >
              <Button
                dataCy="continueButton"
                type="submit"
                green={true}
                textTransform="capitalize"
              >
                Continue
              </Button>
              <ErrorMessage
                name="tripId"
                render={message => <p style={{ color: 'red', marginTop: '1rem' }}>{message}</p>}
              />
            </section>
          </FormLayout>
        );
      }}
    />
  );
};

export default Location;
