import * as React from 'react';
import { useSelector } from 'react-redux';
import { renderNumberOfAdults } from '../../lib/utils';
import { MobilePrice, MobilePriceText } from '../../routes/Checkout/Checkout.styles';
import { Total, TotalTravelers } from '../../routes/Checkout/Steps/Location/Location.styles';
import { Store } from '../../types';
import { Button, OrderPreviewPrice } from '../index';

const MobileBottomBar = () => {
  const { travelers = [] } = useSelector((state: Store) => state.createBookingInput);
  const { orderPreview, orderPreviewLoading } = useSelector((state: Store) => state.order);

  return (
    <MobilePrice>
      <Total>
        <MobilePriceText primary={true} weight="bold" uppercase={true}>
          <>
            <TotalTravelers weight="bold">
              {renderNumberOfAdults(travelers.length)}
            </TotalTravelers>
            <OrderPreviewPrice loading={orderPreviewLoading} orderPreview={orderPreview} />
          </>
        </MobilePriceText>

        <Button
          dataCy="travelerInfoContinueBtn"
          type="submit"
          green={true}
          small={true}
          textTransform="capitalize"
        >
          Continue
        </Button>
      </Total>
    </MobilePrice>
  );
};

export default MobileBottomBar;
