import styled from 'styled-components';

export const Container = styled.section`
  display: grid;
  grid-gap: 1rem;
  background: #fff;
  margin: 2rem;
  padding: 2rem;
  text-align: center;
`;
