import styled from 'styled-components';
import { Laptop, TabletV } from '../Breakpoints/Breakpoints';

export const StyledSection = styled.section`
  flex: none;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 0.625rem;
  border-bottom: 1px solid #e3e3e3;

  @media (${Laptop}) {
    justify-content: center;
    padding: 1.875rem 1rem 1.375rem;
  }
`;

export const Logo = styled.img`
  height: 40px;

  @media (${TabletV}) {
    height: auto;
  }
`;

export const Contact = styled.div`
  display: flex;
  align-items: center;

  @media (${Laptop}) {
    display: none;
  }
`;

export const Text = styled.p`
  font-size: 0.875em;
  color: #669157;
  padding-right: 0.625rem;
  padding-left: 0.375rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const Phone = styled.img``;
