import { ErrorMessage } from 'formik';
import * as React from 'react';
import { Map } from '..';
import { Container, Hint, Input, Label, Select } from './TextField.styles';

interface Props {
  className?: string;
  dataCy?: string;
  maxLength?: number;
  error?: any;
  errorOnTouch?: boolean;
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  hint?: string;
  type: string;
  value?: string | number;
  removeAsterisk?: boolean;
  checked?: boolean;
  pattern?: string;
  onBlur?: (event: any) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSelect?: (event: React.FormEvent<HTMLSelectElement>) => void;
  options?: Array<any>;
}

class TextField extends React.PureComponent<Props> {

  renderTextInput() {
    const {
      className,
      dataCy,
      name,
      type,
      placeholder,
      value,
      required,
      onChange,
      pattern,
      onBlur,
      maxLength
    } = this.props;

    return (
      <>
        <Input
          className={className}
          data-cy={dataCy}
          name={name}
          placeholder={placeholder}
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          required={required}
          pattern={pattern}
          maxLength={maxLength}
        />
        <ErrorMessage
          name={name}
          render={message => <p data-cy={`error.${dataCy}`} style={{ color: 'red', marginTop: '1rem' }}>{message}</p>}
        />
      </>
    );
  }

  renderSelect() {
    const { dataCy, name, placeholder, value, required, onChange, options, onBlur } = this.props;

    if (!options) {
      return null;
    }

    return (
      <>
        <Select
          data-cy={dataCy}
          onBlur={onBlur}
          onChange={onChange as any}
          name={name}
          required={required}
          value={value ? value : ''}
        >
          <option key={0} value="">
            {placeholder}
          </option>
          <Map
            data={options}
            render={(option: any, i: number) => (
              <option key={i + 1} value={option.code}>
                {option.name}
              </option>
            )}
          />
        </Select>
        <ErrorMessage
          name={name}
          render={message => <p data-cy={`error.${dataCy}`} style={{ color: 'red', marginTop: '1rem' }}>{message}</p>}
        />
      </>
    );
  }

  renderHint() {
    return <Hint>{this.props.hint}</Hint>;
  }

  renderLabel() {
    const { label, required, removeAsterisk } = this.props;
    return (
      <Label>
        {label} {required && !removeAsterisk ? '*' : ''}
      </Label>
    );
  }

  render() {
    const { label, hint, type } = this.props;
    return (
      <Container>
        {!!label && this.renderLabel()}
        {type === 'select' && this.renderSelect()}
        {type !== 'checkbox' && type !== 'textarea' && type !== 'select' && this.renderTextInput()}
        {!!hint && this.renderHint()}
      </Container>
    );
  }
}

export default TextField;
