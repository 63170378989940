import * as React from 'react';
import { CSSProperties, ReactChild } from 'react';
import styled from 'styled-components';
import { TabletV } from '../Breakpoints/Breakpoints';

interface Props {
  style?: CSSProperties;
  className?: string;
  children?: React.ReactChild | Array<ReactChild>;
  dataCy?: string;
  text?: string;
  weight?: string;
  large?: boolean;
  small?: boolean;
  verysmall?: boolean;
  center?: boolean;
  uppercase?: boolean;
  italics?: boolean;

  onClick?(): void;
}

function BaseText({ style, className, children, dataCy, text, onClick }: Props) {
  if (text) {
    return (
      <p style={style} data-cy={dataCy} className={className} onClick={onClick}>
        {text}
      </p>
    );
  }
  return (
    <p style={style} data-cy={dataCy} className={className} onClick={onClick}>
      {children}
    </p>
  );
}

export interface IText {
  variant?: 'strong'
}

const Text = styled(BaseText)<IText>`
  font-size: 1em;
  color: #6b6b6b;
  line-height: 1.3;
  font-family: 'Raleway', sans-serif;
 
  ${({ variant }) => {
    switch (variant) {
      case 'strong':
        return 'font-weight: 700; color: #000;';
      default:
        return '';
    }
  }};
  
  ${props => {
  if (props.weight === 'bold') {
    return 'font-weight: 700;';
  } else if (props.weight === 'semibold') {
    return 'font-weight: 600;';
  } else {
    return '';
  }
}};

  ${props => {
  if (props.italics) {
    return 'font-style: italic;';
  } else {
    return '';
  }
}};

  ${props => {
  if (props.large) {
    return 'font-size: 1.4375em;';
  } else if (props.small) {
    return 'font-size: 0.875em;';
  } else if (props.verysmall) {
    return 'font-size: 0.9375em;';
  } else {
    return '';
  }
}};
  ${props => {
  if (props.center) {
    return 'text-align: center;';
  } else {
    return '';
  }
}};
  ${props => {
  if (props.uppercase) {
    return 'text-transform: uppercase;';
  } else {
    return '';
  }
}};

  @media (${TabletV}) {
    font-size: 1.125em;

    ${props => {
  if (props.large) {
    return 'font-size: 1.4375em;';
  } else if (props.verysmall) {
    return 'font-size: 0.9375em;';
  } else {
    return '';
  }
}};
  }
`;

export default Text;
