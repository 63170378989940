import * as React from 'react';
import CheckedImage from '../../assets/images/green-check.png';
import { Checkmark, StyledCheckbox, StyledInput, StyledLabel, StyledRow, StyledSpan } from './Checkbox.styles';

interface Props {
  className?: string;
  dataCy?: string;
  name?: string;
  type?: string;
  label?: string;
  value?: boolean;
  linkText?: string;
  link?: string;

  onChange?(value: boolean): void;
}

function Checkbox(props: Props) {
  const { className, dataCy, type, name, value, label, linkText, link, onChange } = props;

  return (
    <StyledCheckbox
      data-cy={dataCy}
      onClick={() => {
        if (onChange) {
          onChange(!value);
        }
      }}
      className={className + (value ? ' checked' : '')}
    >
      <StyledRow>
        <StyledInput type={type} name={name} defaultChecked={value} />
        <StyledSpan data-cy={`${dataCy}.checkbox`}>
          <Checkmark src={CheckedImage} />
        </StyledSpan>
        <StyledLabel className={value ? ' checked' : ''}>
          {label}&nbsp;
          <span
            className="spanLink"
            onClick={e => {
              e.stopPropagation();
              window.open(link);
            }}
          >
            {linkText}
          </span>
        </StyledLabel>
      </StyledRow>
    </StyledCheckbox>
  );
}

export default Checkbox;
