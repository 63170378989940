import * as React from 'react';
import Moment from 'react-moment';
import Text from '../../Text/Text';
import AngleRightIcon from './AngleRightIcon';

interface Props {
  startDate: string;
  endDate: string;
}

const DatesSection = (props: Props) => {
  const { startDate, endDate } = props;

  const renderDate = (date: string) => (
    <div>
      <Text variant="strong">
        <Moment format="dddd" date={date} />
      </Text>

      <Text>
        <Moment format="D MMMM, YYYY (ddd)" date={date} />
      </Text>
    </div>
  );

  return (
    <section
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        alignItems: 'center',
      }}
    >
      {renderDate(startDate)}

      <AngleRightIcon />

      {renderDate(endDate)}
    </section>
  );
};

export default DatesSection;
