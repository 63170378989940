import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from 'styled-components';

const Loading = styled.div`
  height: 30vh;
  padding-top: 3rem;
  text-align: center;
`;

const LoadingSpinner = () => {
  return (
    <Loading>
      <FontAwesomeIcon spin size="5x" icon={faSpinner} />
    </Loading>
  );
};

export default LoadingSpinner;
