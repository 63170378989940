import * as RA from 'ramda-adjunct';
import * as React from 'react';

interface Props<T> {
  data: Array<T>;

  render(data: T, index: number): React.ReactNode;
}

const Map = (props: Props<any>) => {
  const { data = [], render } = props;
  return (
    <>
      {
        RA.mapIndexed((d, i) => render(d, i))(data)
      }
    </>
  );
};

export default Map;
