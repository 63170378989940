import gql from 'graphql-tag';
import * as R from 'ramda';
import { Epic, StateObservable } from 'redux-observable';
import { mapTo, mergeMap } from 'rxjs/operators';
import { Action } from 'typescript-fsa';
import { IOrderPreview } from '../generated/graphql';
import apolloClient from '../lib/apolloClient';
import { Store } from '../types';
import { GO_TO_STEP } from './checkoutStep';
import { ADD_COUPON, REMOVE_COUPON, UPDATE_BOOKING_SKU } from './createBookingInput';

// Initial State

export interface State {
  orderPreviewLoading: boolean;
  orderPreviewErrorMessage?: string;
  addCouponLoading: boolean;
  addCouponError: boolean;
  addCouponErrorMessage?: string;
  orderPreview?: IOrderPreview;
  travelerCount: number;
}

export const INITIAL_STATE: State = {
  orderPreviewLoading: false,
  addCouponLoading: false,
  addCouponError: false,
  travelerCount: 1,
};

// Constants

export const GET_ORDER_PREVIEW_SUCCESSFUL = 'GET_ORDER_PREVIEW_SUCCESSFUL';
export const GET_ORDER_PREVIEW_FAILED = 'GET_ORDER_PREVIEW_FAILED';
export const GET_ORDER_PREVIEW_LOADING = 'GET_ORDER_PREVIEW_LOADING';
export const GET_ORDER_PREVIEW_NO_CHANGE = 'GET_ORDER_PREVIEW_NO_CHANGE';
export const ADD_COUPON_LOADING = 'ADD_COUPON_LOADING';
export const ADD_COUPON_FAILED = 'ADD_COUPON_FAILED';
export const UPDATE_ORDER_PREVIEW = 'UPDATE_ORDER_PREVIEW';
export const UPDATE_TRAVELER_COUNT = 'UPDATE_TRAVELER_COUNT';

// Reducers

export default function reducer(state = INITIAL_STATE, action: Action<any>) {
  switch (action.type) {
    case ADD_COUPON_LOADING:
      return {
        ...state,
        addCouponLoading: true,
        addCouponError: false,
      };
    case ADD_COUPON_FAILED:
      return {
        ...state,
        addCouponError: true,
        addCouponErrorMessage: action.payload,
        addCouponLoading: false,
        orderPreviewLoading: false,
      };
    case GET_ORDER_PREVIEW_LOADING:
      return {
        ...state,
        orderPreviewLoading: true,
      };
    case GET_ORDER_PREVIEW_SUCCESSFUL:
      return {
        ...state,
        orderPreviewLoading: false,
        addCouponLoading: false,
        addCouponError: false,
        addCouponErrorMessage: undefined,
        orderPreview: action.payload,
      };
    case GET_ORDER_PREVIEW_FAILED:
      return {
        ...state,
        orderPreviewLoading: false,
        orderPreviewErrorMessage: action.payload,
      };
    case GET_ORDER_PREVIEW_NO_CHANGE:
      return {
        ...state,
        orderPreviewLoading: false,
        addCouponLoading: false,
        addCouponError: false,
        addCouponErrorMessage: undefined,
      };
    case UPDATE_TRAVELER_COUNT:
      return {
        ...state,
        travelerCount: action.payload,
      };
    default:
      return state;
  }
}

// Actions

export const addCouponLoading = () => ({
  type: ADD_COUPON_LOADING,
});

export const getOrderPreviewLoading = () => ({
  type: GET_ORDER_PREVIEW_LOADING,
});

export const getOrderPreviewSuccessful = (payload: IOrderPreview | {}) => ({
  type: GET_ORDER_PREVIEW_SUCCESSFUL,
  payload,
});

export const getOrderPreviewNoChange = (payload?: any) => ({
  type: GET_ORDER_PREVIEW_NO_CHANGE,
  payload,
});

export const getOrderPreviewFailed = <T = any>(payload?: T) => ({
  type: GET_ORDER_PREVIEW_FAILED,
  payload,
});

export const addCouponFailed = <T = any>(payload?: T) => ({
  type: ADD_COUPON_FAILED,
  payload,
});

export const updateOrderPreview = (payload?: any) => ({
  type: UPDATE_ORDER_PREVIEW,
  payload,
});

export const updateTravelerCount = (payload: number) => ({
  type: UPDATE_TRAVELER_COUNT,
  payload,
});

// Epics

export const GET_ORDER_PREVIEW = gql`
    mutation getOrderPreview($input: GetOrderPreviewInput!) {
        getOrderPreview(input: $input) {
            amount
            items {
                type
                amount
                description
                parent
                currency
                quantity
                global
            }
        }
    }
`;

export const addCouponLoaderEpic: Epic = actions$ => actions$.ofType(ADD_COUPON).pipe(mapTo(addCouponLoading()));

export const getOrderPreviewLoaderEpic: Epic = actions$ =>
  actions$
    .ofType(
      ADD_COUPON,
      REMOVE_COUPON,
      UPDATE_BOOKING_SKU,
      UPDATE_TRAVELER_COUNT,
      '@@redux-form/ARRAY_POP',
      '@@redux-form/ARRAY_PUSH',
    )
    .pipe(mapTo(getOrderPreviewLoading()));

export const getOrderPreviewEpic: Epic = (actions$, store: StateObservable<Store>) => {
  return actions$
    .ofType(
      GO_TO_STEP,
      UPDATE_ORDER_PREVIEW,
      UPDATE_TRAVELER_COUNT,
      ADD_COUPON,
      REMOVE_COUPON,
      UPDATE_BOOKING_SKU,
      '@@redux-form/ARRAY_POP',
      '@@redux-form/ARRAY_PUSH',
    )
    .pipe(
      mergeMap(async (action: Action<any>) => {
        const { createBookingInput, order } = store.value;
        const { travelerCount } = order;

        if (R.isNil(createBookingInput.sku)) {
          return getOrderPreviewNoChange({});
        }

        try {
          const res = await apolloClient.mutate<{ getOrderPreview: IOrderPreview }>({
            mutation: GET_ORDER_PREVIEW,
            variables: {
              input: {
                quantity: travelerCount,
                sku: createBookingInput.sku,
                couponId: createBookingInput.couponId,
              },
            },
          });

          if (res.data) {
            return getOrderPreviewSuccessful(res.data.getOrderPreview);
          }
          return getOrderPreviewSuccessful({});
        } catch (err) {
          console.error(err);

          if (
            R.test(/GraphQL error: \d (INVALID_ARGUMENT|NOT_FOUND|FAILED_PRECONDITION)/g)(err.message) &&
            R.test(/(coupon|Coupon)/g)(err.message)
          ) {
            return addCouponFailed(err.message);
          }

          return getOrderPreviewFailed(err.message);
        }
      }),
    );
};
