import { ApolloError } from 'apollo-client';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { GraphQLError } from 'graphql';
import * as L from 'partial.lenses';
import qs from 'querystring';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  Button,
  Map,
  PaymentInput,
  ReviewTravelerInfo,
  SKURadioGroup,
  Text,
  Title,
  Title2,
  useStripe,
} from '../../../../components';
import { useSession } from '../../../../components/Session/Session';
import {
  ICreateBookingInput,
  ILocation,
  useCreateBookingMutation,
  useListPaymentOptionsQuery,
} from '../../../../generated/graphql';
import bugsnagClient from '../../../../lib/bugsnagClient';
import config from '../../../../lib/config';
import currencyFormatter from '../../../../lib/currencyFormatter';
import { convertDollars, findDeposit, findSelectedTrip, findSelectedTripSKU } from '../../../../lib/utils';
import { updateBooking, updateBookingSKU } from '../../../../redux/createBookingInput';
import { Store } from '../../../../types';
import { LeftContSection, PaymentDetails, PaymentError, PaymentLoading, PurchaseSection } from '../../Checkout.styles';

const INITIAL_VALUES = {
  cardHolderName: '',
  sku: '',
};

const validationSchema = Yup.object().shape({
  cardHolderName: Yup.string().required('Cardholder name is required'),
  sku: Yup.string().required('Required'),
});

interface Props {
  location: ILocation;
}

const PaymentStepForm = (props: Props) => {
  const { location } = props;
  const { stripe } = useStripe();
  const createBookingInput = useSelector((state: Store) => state.createBookingInput);
  const [createBooking] = useCreateBookingMutation();
  const order = useSelector((state: Store) => state.order);
  const dispatch = useDispatch();
  const { sessionId, saveSession } = useSession();

  const { tripId, travelers = [], sku, couponId } = createBookingInput;
  const { orderPreview = { amount: 0, items: [] } } = order;
  const trips = location.trips ?? [];

  const paymentOptionsQuery = useListPaymentOptionsQuery({
    variables: {
      input: {
        couponId,
        quantity: travelers.length,
        sku,
        tripId,
      },
    },
  });

  let referralCode = '';
  const referrer = window.location.search !== '' ? document.referrer : window.location.search;
  if (RA.isNotEmpty(referrer)) {
    const params = qs.parse(referrer.split('?')[1]);
    referralCode = params.ref as string;
  }

  const selectedTrip = findSelectedTrip(trips, tripId);
  const selectedTripSKU = findSelectedTripSKU(selectedTrip?.skus, sku!);
  const isDepositSKU = selectedTripSKU?.type === 'deposit';
  const deposit = findDeposit(paymentOptionsQuery.data?.paymentOptions ?? []);

  const formatted = {
    depositPrice: currencyFormatter.format(convertDollars(deposit?.price ?? 0)),
    chargePrice: currencyFormatter.format(convertDollars(orderPreview!.amount!)),
  };

  const renderStatus = (status: any) => {
    switch (true) {
      case typeof status === 'string':
        return <p style={{ marginTop: '1rem' }}>{status}</p>;
      case status instanceof ApolloError: {
        if (!status.graphQLErrors.length) {
          return <PaymentError small>There was an error processing your order</PaymentError>;
        }

        return (
          <Map
            data={status.graphQLErrors}
            render={(err: GraphQLError, index: number) =>
              <PaymentError key={index} small>{err.message}</PaymentError>}
          />
        );
      }
      case status && status.type === 'validation_error':
      case status instanceof Error:
        return <PaymentError small>{status.message}</PaymentError>;
      default:
        return null;
    }
  };

  const onSubmit = async (values: any, { setSubmitting, setStatus }: any) => {
    try {
      setSubmitting(true);
      setStatus(undefined);
      const { couponId, ...rest } = values; // TODO some reason couponId is being reset here. temporary fix to not clear couponId when updating booking
      dispatch(updateBooking(rest));

      const { token, error } = await stripe!.createToken({ name: values.cardHolderName });

      if (error) {
        throw error;
      }

      if (!token) {
        console.error('Stripe token not found');
        throw new Error('Stripe token not found');
      }

      const input: ICreateBookingInput = R.pipe(
        L.set('token', token.id),
        L.modify(['travelers', L.elems, 'birthday'], ({ year, month, day }: any) => `${year}-${month}-${day}`),
        L.modify(['travelers', L.elems, 'passport', 'country'], (country: string) => country === '' ? undefined : country),
        L.remove(['travelers', L.elems, 'emailVerification']),
        L.remove(['couponId', L.when(R.isEmpty)]),
        L.remove('cardHolderName'),
        L.set('referralCode', () => RA.isNilOrEmpty(referralCode) ? referralCode : undefined),
      )
        // @ts-ignore
        ({ ...values, couponId: createBookingInput.couponId }); // TODO couponId not set in certain conditions

      bugsnagClient.leaveBreadcrumb('before data submit', { input });
      const res = await createBooking({ variables: { input } });
      bugsnagClient.leaveBreadcrumb('after data submit', { res });

      travelers.forEach((traveler: any) => {
        // @ts-ignore
        klaviyo.identify(
          {
            $email: traveler.email,
            $first_name: traveler.firstName,
            $last_name: traveler.lastName,
            $phone_number: traveler.phone
          },
          () => {
            const event = {
              tripId: tripId,
              sku: sku
            };
            // @ts-ignore
            klaviyo.push(['track', 'Checkout completed', event]);
          }
        );
      });

      setStatus('Purchase successful. Redirecting...');

      sessionStorage.clear();

      const { bookingId, trip } = res.data!.createBooking!;

      saveSession!({ ...values, completed: true, bookingId });

      setTimeout(() => {
        window.location.href = `${config.u30xReceiptUrl}?booking_id=${bookingId}&location_id=${trip!.locationId}`;
      }, 3000);
    } catch (err) {
      console.error(err);

      if (err.type !== 'validation_error') {
        bugsnagClient.notify(err);
      }

      setStatus(err);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues: any = R.pipe(
    R.mergeDeepRight(INITIAL_VALUES),
    L.set('sessionId', sessionId),
  )(createBookingInput);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={({ status, isSubmitting, setFieldValue }: FormikProps<any>) => (
        <Form
          style={{
            gridArea: 'form',
          }}
          data-cy="paymentStep"
        >
          <LeftContSection>
            <Title primary weight="bold">
              Review your details
            </Title>

            <ReviewTravelerInfo />

            <Title2 primary weight="bold">
              Payment Options
            </Title2>

            <Text small>
              *If paying with deposit any remaining balance is always due 60 days before the trip. You'll receive
              an invoice by email. *If signing up for a trip within 60 days, the deposit option is not available. Early
              sign up price is only available when paying in full at the time of sign up.
            </Text>

            <Field
              name="sku"
              render={({ field }: FieldProps<any>) => (
                <SKURadioGroup
                  {...field}
                  onChange={(e) => {
                    dispatch(updateBookingSKU(e.target.value));
                    setFieldValue(field.name, e.target.value);
                  }}
                  options={paymentOptionsQuery.data?.paymentOptions ?? []}
                />
              )}
            />

            {
              isDepositSKU &&
              <Text small>When paying with a deposit all promotions and coupon codes will be reflected on your emailed
                invoice.</Text>
            }

            <PaymentInput />

            <PurchaseSection>
              <PaymentDetails>
                <Title2 primary weight="bold">
                  {`Your card will be charged: ${formatted.chargePrice}`}
                </Title2>

                <Text small>**Processed securely through Stripe. <br /> We never store your Credit Card number or CVC
                  number!</Text>
              </PaymentDetails>

              <PaymentLoading>
                <Button
                  dataCy="travelerInfoContinueBtn"
                  type="submit"
                  green
                  textTransform="capitalize"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Purchase
                </Button>

                {renderStatus(status)}
              </PaymentLoading>
            </PurchaseSection>
          </LeftContSection>
        </Form>
      )}
    />
  );
};

export default PaymentStepForm;
