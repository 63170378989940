import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface Props {
  loading?: boolean;
  children: React.ReactComponentElement<any>;
}

const Loading = (props: Props) => {
  const { loading, children } = props;

  if (loading) {
    return <div style={{ textAlign: 'right' }}><FontAwesomeIcon spin icon={faSpinner} /></div>;
  }

  return children;
};

export default Loading;
