import { Field, FieldProps, Form, Formik } from 'formik';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCoupon } from '../../redux/createBookingInput';
import { PromoForm, PromoInvalidResponse } from '../../routes/Checkout/Steps/Location/Location.styles';
import { Store } from '../../types';
import { Button, TextField } from '../index';

const INITIAL_VALUES = {
  couponId: '',
};

interface Props {
  disabled?: boolean;
}

const CouponForm = (props: Props) => {
  const { disabled } = props;
  const createBookingInput = useSelector((state: Store) => state.createBookingInput);
  const { addCouponError, addCouponLoading } = useSelector((state: Store) => state.order);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={R.mergeDeepRight(INITIAL_VALUES, createBookingInput)}
      onSubmit={(values: any) => {
        dispatch(addCoupon(values.couponId));
      }}
      render={() => (
        <Form>
          <PromoForm>
            <Field
              name="couponId"
              render={({ field }: FieldProps) => (
                <TextField
                  {...field}
                  placeholder="Add promo code"
                  type="text"
                />
              )}
            />

            <Button
              style={{
                maxWidth: '8.3125rem',
              }}
              type="submit"
              invertedGreen={true}
              textTransform="capitalize"
              small={true}
              loading={addCouponLoading}
              disabled={addCouponLoading || disabled}
            >
              Add
            </Button>
          </PromoForm>

          {addCouponError && <PromoInvalidResponse>Invalid coupon</PromoInvalidResponse>}
        </Form>
      )}
    />
  );
};

export default CouponForm;
