import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Desktop, Laptop, TabletH, TabletV } from '../Breakpoints/Breakpoints';

interface Props {
  style?: CSSProperties;
  className?: string;
  children?: React.ReactChild;
  dataCy?: string;
  primary?: boolean;
  green?: boolean;
  white?: boolean;
  grey?: boolean;
  weight?: string;
  uppercase?: boolean;
}

const StyledH1 = styled.h1`
  margin: 0;
  line-height: 1.2;
`;

function BaseTitle({ style, className, children, dataCy }: Props) {
  return (
    <StyledH1 style={style} data-cy={dataCy} className={className}>
      {children}
    </StyledH1>
  );
}

export const Title = styled(BaseTitle)`
  font-size: 1.375em;
  font-family: 'Raleway', sans-serif;
  ${props => {
  if (props.primary) {
    return 'color: #2f4427;';
  } else if (props.white) {
    return 'color: #fff;';
  } else if (props.green) {
    return 'color: #659254;';
  } else if (props.grey) {
    return 'color: #6b6b6b;';
  } else {
    return 'color: #000;';
  }
}};
  ${props => {
  if (props.weight === 'bold') {
    return 'font-weight: 700;';
  } else if (props.weight === 'semibold') {
    return 'font-weight: 600;';
  } else {
    return '';
  }
}};

  ${props => {
  if (props.uppercase) {
    return 'text-transform: uppercase;';
  } else {
    return '';
  }
}};

  @media (${TabletV}) {
    font-size: 1.625em;
  }

  @media (${TabletH}) {
    font-size: 1.875em;
  }

  @media (${Laptop}) {
    font-size: 2em;
  }

  @media (${Desktop}) {
    font-size: 2.3125em;
  }
`;

export const Title2 = styled(Title)`
  margin-bottom: 0.5rem;
  font-size: 1.125em;

  @media (${TabletH}) {
    font-size: 1.25em;
  }

  @media (${Laptop}) {
    font-size: 1.5em;
  }

  @media (${Desktop}) {
    font-size: 1.5625em;
  }
`;

export const Title3 = styled(Title)`
  font-size: 1.125em;

  @media (${TabletV}) {
    font-size: 1.25em;
  }

  @media (${Laptop}) {
    font-size: 1.5em;
  }

  @media (${Desktop}) {
    font-size: 1.5625em;
  }
`;

export const Title4 = styled(Title)`
  font-size: 1.25em;

  @media (${TabletV}) {
    font-size: 1.375em;
  }

  @media (${TabletH}) {
    font-size: 1.5em;
  }

  @media (${Laptop}) {
    font-size: 1.5625em;
  }

  @media (${Desktop}) {
    font-size: 1.75em;
  }
`;

export const Title5 = styled(Title)`
  font-size: 1.125em;

  @media (${TabletV}) {
    font-size: 1.25em;
  }

  @media (${Laptop}) {
    font-size: 1.375em;
  }

  @media (${Desktop}) {
    font-size: 1.4375em;
  }
`;

export const Title6 = styled(Title)`
  font-size: 1.125em;

  @media (${TabletV}) {
    font-size: 1.5em;
  }

  @media (${Laptop}) {
    font-size: 1.625em;
  }

  @media (${Desktop}) {
    font-size: 1.875em;
  }
`;

export const FieldTitle = styled(Title)`
  font-size: 1em;
  padding-bottom: 0.25rem;

  @media (${TabletV}) {
    font-size: 1.125em;
  }

  @media (${TabletH}) {
    padding-bottom: 0.75rem;
  }

  ${props => {
  if (props.weight === 'bold') {
    return 'font-weight: 700;';
  } else if (props.weight === 'semibold') {
    return 'font-weight: 600;';
  } else {
    return '';
  }
}};
`;
