import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { FieldTitle, InputGroup, InputGroup3, TextField, Title2 } from '../../../../components';
import { ITraveler } from '../../../../generated/graphql';
import countries from '../../../../lib/countries';
import { InfoSectionPaddingTop } from '../../Checkout.styles';

const AdditionalDetailFields = () => (
  <Field
    name="travelers"
    render={({ field }: FieldProps<any>) => field.value.map((traveler: ITraveler, i: number) => (
      <section key={i}>
        <Title2 primary={true} weight="bold">
          {`Traveler ${i + 1}`}
        </Title2>

        <InfoSectionPaddingTop>
          <FieldTitle weight="semibold">
            Address (required)
          </FieldTitle>

          <InputGroup>
            <Field
              name={`travelers[${i}].address.line1`}
              render={({ field }: FieldProps<any>) => (
                <TextField
                  {...field}
                  dataCy={field.name}
                  placeholder="Address"
                  type="text"
                />
              )}
            />
          </InputGroup>

          <InputGroup>
            <Field
              name={`travelers[${i}].address.line2`}
              render={({ field }: FieldProps<any>) => (
                <TextField
                  {...field}
                  dataCy={field.name}
                  placeholder="Address 2"
                  type="text"
                />
              )}
            />
          </InputGroup>

          <InputGroup3>
            <Field
              name={`travelers[${i}].address.city`}
              render={({ field }: FieldProps<any>) => (
                <TextField
                  {...field}
                  dataCy={field.name}
                  placeholder="City"
                  type="text"
                />
              )}
            />

            <Field
              name={`travelers[${i}].address.region`}
              render={({ field }: FieldProps<any>) => (
                <TextField
                  {...field}
                  dataCy={field.name}
                  placeholder="State"
                  type="text"
                />
              )}
            />

            <Field
              name={`travelers[${i}].address.postalCode`}
              render={({ field }: FieldProps<any>) => (
                <TextField
                  {...field}
                  dataCy={field.name}
                  placeholder="Postal Code"
                  type="text"
                />
              )}
            />
          </InputGroup3>

          <InputGroup>
            <Field
              name={`travelers[${i}].address.country`}
              render={({ field }: FieldProps<any>) => (
                <TextField
                  {...field}
                  dataCy={field.name}
                  placeholder="Country"
                  options={countries}
                  type="select"
                />
              )}
            />
          </InputGroup>
        </InfoSectionPaddingTop>
      </section>
    ))}
  />
);

export default AdditionalDetailFields;
