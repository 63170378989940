import styled from 'styled-components';
import * as breakpoints from '../Breakpoints/Breakpoints';

export const StyledTravelerCounter = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 0.5rem;
`;

export const InputCounter = styled.input`
  background: #eef2ec;
  border: 1px solid #e3e3e3;
  height: 2.6875rem;
  width: 2.6875rem;
  color: #669157;
  font-size: 1.75em;

  @media (${breakpoints.TabletV}) {
    height: 3.75rem;
    width: 3.75rem;
  }
`;

export const InputCount = styled.div`
  background: #fff;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  color: #000;
  padding: 0 1.375rem;
  height: 2.5625rem;
  display: flex;
  align-items: center;
  justify-items: center;
  width: auto;

  @media (${breakpoints.TabletV}) {
    height: 3.625rem;
    padding: 0 1.75rem;
  }
`;
