import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import checkoutStep from './checkoutStep';
import createBookingInput from './createBookingInput';
import order, { addCouponLoaderEpic, getOrderPreviewEpic, getOrderPreviewLoaderEpic } from './order';

export const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    checkoutStep,
    createBookingInput,
    order
  });

export const rootEpic = combineEpics(
  getOrderPreviewEpic,
  getOrderPreviewLoaderEpic,
  addCouponLoaderEpic
);
