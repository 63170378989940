import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Step, Steps, StyledSection } from './ProgressBar.styles';

interface Props {
  style?: CSSProperties;
  data: Array<any>;
  currentStep?: number;

  onClick?(data: any, index: number): void;
}

function ProgressBar(props: Props) {
  const { style, currentStep, onClick } = props;

  const handleOnClick = (data: any, index: number) => () => {
    if (onClick) {
      onClick(data, index);
    }
  };

  const classStatus = (index: number) => {
    if (R.equals(index, currentStep)) {
      return 'current';
    }

    if (R.lt(index, currentStep || 0)) {
      return 'previous';
    }

    return '';
  };

  return (
    <StyledSection style={style}>
      <Steps>
        {RA.mapIndexed(
          (data: any, i: number) => (
            <Step key={i} className={classStatus(i)} onClick={handleOnClick(data, i)}>
              <span className="desktop">{`${i + 1}. ${data.name.desktop}`}</span>
              <span className="tablet">{`${i + 1}. ${data.name.tablet}`}</span>
              <span className="mobile">{`${i + 1}.`}</span>
            </Step>
          ),
          props.data
        )}
      </Steps>
    </StyledSection>
  );
}

export default ProgressBar;
