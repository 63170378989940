import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import 'rxjs/add/operator/mergeMap';
import { Action } from 'typescript-fsa';
import uuid from 'uuid/v4';
import { IRoomArrangement, ITripSku } from '../generated/graphql';
import { ADD_COUPON_FAILED } from './order';

// Initial State

export const INITIAL_STATE = {
  idempotencyKey: uuid(),
  returnCustomer: undefined,
  sku: '',
  acceptTerms: false,
  acceptCancellation: false,
  roomArrangement: IRoomArrangement.Solo,
  friendName: '',
  cardHolderName: '',
  couponId: '',
  tripId: '',
  travelers: [
    {
      firstName: '',
      lastName: '',
      birthday: {
        month: '',
        day: '',
        year: '',
      },
      address: {
        line1: '',
        city: '',
        region: '',
        postalCode: '',
        country: ''
      },
      passport: {
        number: '',
        expiration: '',
        country: '',
      },
      gender: '',
      email: '',
      emailVerification: '',
      phone: '',
    },
  ]
};

// Constants

export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const UPDATE_BOOKING_SKU = 'UPDATE_BOOKING_SKU';
export const SELECT_TRIP = 'SELECT_TRIP';
export const ADD_COUPON = 'ADD_COUPON';
export const REMOVE_COUPON = 'REMOVE_COUPON';
export const CLEAR_CREATE_BOOKING = 'CLEAR_CREATE_BOOKING';

// Reducers

export default function reducer(state = INITIAL_STATE, action: Action<any>) {
  switch (action.type) {
    case UPDATE_BOOKING:
      return R.merge(state, action.payload);
    case UPDATE_BOOKING_SKU:
      return R.assoc('sku', action.payload, state);
    case SELECT_TRIP:
      return R.pipe(
        R.assoc('tripId', action.payload.tripId),
        R.assoc('sku', action.payload.sku),
        R.assoc('couponId', RA.stubUndefined())
      )(state);
    case ADD_COUPON:
      return R.assoc('couponId', action.payload, state);
    case REMOVE_COUPON:
    case ADD_COUPON_FAILED:
      return R.assoc('couponId', RA.stubUndefined(), state);
    case CLEAR_CREATE_BOOKING:
      return INITIAL_STATE;
    default:
      return state;
  }
}

// Actions

export const updateBooking = (payload?: any) => ({
  type: UPDATE_BOOKING,
  payload
});

export const updateBookingSKU = (payload?: any) => ({
  type: UPDATE_BOOKING_SKU,
  payload
});

export const selectTrip = (payload: ITripSku) => ({
  type: SELECT_TRIP,
  payload
});

export const addCoupon = (payload: string) => ({
  type: ADD_COUPON,
  payload
});

export const removeCoupon = () => ({
  type: REMOVE_COUPON
});

export const clearCreateBooking = () => ({
  type: CLEAR_CREATE_BOOKING
});
