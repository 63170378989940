import styled from 'styled-components';
import { TabletV } from '../Breakpoints/Breakpoints';
import Text from '../Text/Text';

export const Image = styled.img`
  width: 100%;

  @media print {
    display: none;
  }
`;

export const Info = styled.div`
  border-bottom: 1px solid #dbdbdb;
  padding: 1.5rem;

  @media (${TabletV}) {
    padding: 2.5rem;
  }
`;

export const PriceItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

export const Total = styled(Text)`
  font-weight: bold;
  color: #2f4427;
  font-size: 1.375em;

  @media (${TabletV}) {
    font-size: 1.875em;
  }
`;

export const List = styled.ul`
  display: grid;
  grid-gap: 0.5rem;
`;
