import styled from 'styled-components';
import { Desktop, Laptop, TabletH, TabletV } from '../Breakpoints/Breakpoints';

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.625rem;

  @media (${TabletH}) {
    padding-bottom: 0.75rem;
  }

  @media (${Laptop}) {
    padding-bottom: 0.875rem;
  }

  @media (${Desktop}) {
    padding-bottom: 0.9375rem;
  }

  &.isStripe {
    padding-bottom: 0;

    &:last-child {
      padding-bottom: 0;
    }

    @media (${TabletV}) {
      padding-bottom: 0.625rem;
    }

    @media (${TabletH}) {
      padding-bottom: 0.75rem;
    }

    @media (${Laptop}) {
      padding-bottom: 0.875rem;
    }

    @media (${Desktop}) {
      padding-bottom: 0.9375rem;
    }

    input {
      font-size: 1.125em;
    }
    > div {
      font-size: 0.9375em;
      border: 1px solid #cfcfcf;
      border-radius: 3px;
      box-sizing: border-box;
      padding: 0.75rem !important;
      width: 100%;
      color: #6b6b6b;
      font-family: 'Raleway', sans-serif;
      margin-bottom: 0.625rem;

      &:last-child {
        margin-right: 0 !important;
      }

      @media (${TabletV}) {
        margin-right: 0.625rem;
        margin-bottom: 0;
      }

      @media (${TabletH}) {
        font-size: 1em;
        padding: 0.9375rem !important;
        margin-right: 0.75rem;
      }

      @media (${Laptop}) {
        font-size: 1.125em;
        padding: 1.25rem 1rem !important;
        margin-right: 0.875rem;
      }

      @media (${Desktop}) {
        margin-right: 0.9375rem;
      }
    }
  }
`;

export const InputGroup2 = styled(InputGroup)`
  > *:not(:last-child) {
    padding-bottom: 0.625rem;
  }

  @media (${TabletV}) {
    flex-direction: row;
    > *:not(:last-child) {
      padding-right: 0.875rem;
    }
    > * {
      width: 100%;
      padding-bottom: 0rem !important;
    }
  }
`;

export const InputGroup3 = styled(InputGroup)`
  > *:not(:last-child) {
    padding-bottom: 0.625rem;
  }

  @media (${TabletV}) {
    flex-direction: row;
    > *:not(:last-child) {
      padding-right: 0.875rem;
    }
    > * {
      width: 100%;
      padding-bottom: 0rem !important;
    }
  }
`;
