import * as React from 'react';
import { useContext } from 'react';
import uuid from 'uuid/v4';
import { useSaveSessionMutation } from '../../generated/graphql';
import bugsnagClient from '../../lib/bugsnagClient';

interface Props {
  children: any;
  key?: string;
}

interface SessionContext {
  sessionId?: string;

  saveSession?(values: object): void;
}

export const SessionContext = React.createContext<SessionContext>({});

export const SessionProvider = (props: Props) => {
  const { children, key = 'sessionId' } = props;
  const [saveSession] = useSaveSessionMutation();

  let sessionId = sessionStorage.getItem(key);

  if (!sessionId) {
    sessionId = uuid();
    sessionStorage.setItem(key, sessionId);
  }

  console.log('Session ID', sessionId);
  bugsnagClient.leaveBreadcrumb('Session ID', sessionId);

  return (
    <SessionContext.Provider
      value={{
        sessionId,
        saveSession(values: any) {
          saveSession({ variables: { id: sessionId!, session: JSON.stringify(values) } })
            .catch(console.error);
        },
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  return useContext(SessionContext);
};
