import { ErrorMessage } from 'formik';
import * as React from 'react';
import Radio from '../Radio/Radio';
import { default as RadioGroup, RadioGroupArea } from '../RadioGroup/RadioGroup';

interface Props {
  name: string;
  value?: any;

  onChange?(e: any): void;
}

function GenderRadioGroup(props: Props) {
  const { name, value, onChange } = props;

  return (
    <RadioGroupArea>
      <RadioGroup>
        <Radio
          dataCy={`${name}.male`}
          label="Male"
          value="male"
          name={name}
          checked={value}
          onChange={onChange}
        />
        <Radio
          dataCy={`${name}.female`}
          label="Female"
          value="female"
          name={name}
          checked={value}
          onChange={onChange}
        />
      </RadioGroup>
      <ErrorMessage
        name={name}
        render={message => <p style={{ color: 'red', marginTop: '1rem' }}>{message}</p>}
      />
    </RadioGroupArea>
  );
}

export default GenderRadioGroup;
