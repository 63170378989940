import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { CardCVCElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements';
import { InputGroup, InputGroup2, Title2 } from '..';
import TextField from '../TextField/TextField';
import { StripeStyling } from './PaymentInput.styles';

const PaymentInput = () => (
  <section>
    <Title2 primary={true} weight="bold">
      Payment
    </Title2>

    <InputGroup>
      <Field
        name="cardHolderName"
        render={({ field }: FieldProps<any>) => (
          <TextField
            {...field}
            dataCy={field.name}
            className="isStripe"
            placeholder="Name on the Card"
            type="text"
          />
        )}
      />
    </InputGroup>

    <InputGroup className="isStripe">
      <CardNumberElement className="cardnumber" placeholder="Card Number" style={StripeStyling} />
    </InputGroup>

    <InputGroup2 className="isStripe">
      <CardExpiryElement className="exp-date" style={StripeStyling} />

      <CardCVCElement className="cvc" style={StripeStyling} />
    </InputGroup2>
  </section>
);

export default PaymentInput;
