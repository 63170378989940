import * as React from 'react';
import { Links, StyledSection, StyledText } from './Footer.styles';

interface Props {
  className?: string;
}

const Footer = (props: Props) => (
  <StyledSection className={props.className}>
    <Links>
      <StyledText weight="bold">
        <a href="https://www.under30experiences.com/terms-of-service" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
      </StyledText>
    </Links>

    <StyledText>&copy; 2018 Shadow Concepts LLC. All rights reserved.</StyledText>
    <StyledText>
      “Under30Experiences,” “U30X” &amp; all associated proprietary marks are trademarks of Shadow Concepts LLC
      &amp; its related entities.
    </StyledText>
    <StyledText>Other third-party trademarks are the property of their respective owners.</StyledText>
  </StyledSection>
);

export default Footer;
