import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import './App.css';
import Checkout from './routes/Checkout/Checkout';

interface Props extends RouteComponentProps<{ locationId: string }> {

}

function App(props: Props) {
  const { locationId } = props.match.params;
  return (
    <Checkout locationId={locationId} />
  );
}

export default App;
