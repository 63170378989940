import styled from 'styled-components';
import { TabletV } from '../Breakpoints/Breakpoints';

export const StyledCheckbox = styled.div`
  background: #fff;
  border: 1px solid #e3e3e3;
  cursor: pointer;

  span {
    background: #fff;
  }

  &:hover {
    border: 1px solid #334d2b;
  }

  img {
    visibility: hidden;
  }

  &.checked {
    background: #334d2b;
    p {
      color: #fff;
    }

    span {
      background: #fff;
      color: #fff;
    }

    img {
      visibility: visible;
    }
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 1rem;

  :hover span {
    background-color: #eee;
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  display: none;
  opacity: 0;

  :hover {
    content: '';
    position: absolute;
    display: none;
  }

  :checked + span:after {
    display: block;
  }

  :checked + img,
  :hover + img {
    display: block;
  }
`;

export const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0.5rem;
  border-radius: 0;
  border: 3px solid #eeeeee;
  text-align: center;
  font-size: 1em;
  color: #282828;
  height: 1.25rem;
  width: 1.25rem;
  cursor: pointer;

  @media (${TabletV}) {
    height: 1.6875rem;
    width: 1.6875rem;
    top: -1.25rem;
  }
`;

export const Checkmark = styled.img``;

export const StyledLabel = styled.p`
  font-size: 0.75em;
  color: #6b6b6b;
  line-height: 1.3;
  font-family: 'Raleway', sans-serif;
  padding-left: 0.75rem;
  font-family: 'Raleway', sans-serif;

  @media (${TabletV}) {
    font-size: 1.125em;
    line-height: 1.3;
  }

  .spanLink {
    color: #659254;
    background: transparent !important;
    text-decoration: underline;

    &:hover {
      color: #95ba87;
    }
  }

  &.checked {
    a {
      color: #fff;
    }
  }
`;
