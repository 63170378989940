import { DateTime } from 'luxon';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Map } from '../../components';
import { ITraveler } from '../../generated/graphql';
import { Store } from '../../types';
import {
  BlackText,
  Info,
  List,
  ListItem,
  ListItemFull,
  StyledSection,
  StyledText,
  TravelerDetails,
  TravelerDetailsFull,
  TravelerInfo,
  TravelerInfoTitle,
} from './ReviewTravelerInfo.styles';

const ReviewTravelerInfo = () => {
  const createBookingInput = useSelector((state: Store) => state.createBookingInput);
  const { travelers, roomArrangement, friendName } = createBookingInput;

  const renderBirthday = (item: any): string => {
    try {
      return DateTime.fromObject({
        day: Number.parseInt(item.birthday.day, 10),
        month: Number.parseInt(item.birthday.month, 10),
        year: Number.parseInt(item.birthday.year, 10),
      }).toFormat('dd LLL, yyyy');
    } catch (err) {
      return '';
    }
  };

  return (
    <StyledSection>
      <Map
        data={travelers}
        render={(item: ITraveler, i: number) => (
          <TravelerInfo data-cy={`reviewTravelerInfo.${i}`} key={i}>
            <TravelerInfoTitle weight="bold">{`Traveler ${i + 1}`}</TravelerInfoTitle>
            <TravelerDetails>
              <Info>
                <List>
                  <ListItem>
                    <BlackText weight="bold">Name:</BlackText>
                    <StyledText>{`${item.firstName || ''} ${item.lastName || ''}`}</StyledText>
                  </ListItem>
                  <ListItem>
                    <BlackText weight="bold">Birthday:</BlackText>
                    <StyledText>
                      {renderBirthday(item.birthday)}
                    </StyledText>
                  </ListItem>
                  <ListItem>
                    <BlackText weight="bold">Gender:</BlackText>
                    <StyledText>{item.gender!}</StyledText>
                  </ListItem>
                  <ListItem>
                    <BlackText weight="bold">Email:</BlackText>
                    <StyledText>{item.email!}</StyledText>
                  </ListItem>
                  <ListItem>
                    <BlackText weight="bold">Phone:</BlackText>
                    <StyledText>{item.phone!}</StyledText>
                  </ListItem>
                  {item.address && (
                    <ListItem>
                      <BlackText weight="bold">Address:</BlackText>
                      <StyledText>
                        {`${item.address.line1} ${item.address.line2 ? item.address.line2 : ''}`}
                        <br />
                        {`${item.address.city} ${item.address.region} ${item.address.postalCode}`}
                      </StyledText>
                    </ListItem>
                  )}
                </List>
              </Info>
            </TravelerDetails>
          </TravelerInfo>
        )}
      />

      <TravelerInfo>
        <TravelerDetailsFull>
          <Info>
            <List>
              <ListItemFull data-cy="reviewTravelerInfo.roomArrangement">
                <BlackText className="black" weight="bold">
                  Room Arrangement:
                </BlackText>
                <StyledText>{roomArrangement}</StyledText>
              </ListItemFull>
              {
                friendName && (
                  <ListItemFull>
                    <BlackText className="black" weight="bold">
                      Friend Name:
                    </BlackText>
                    <StyledText>{friendName}</StyledText>
                  </ListItemFull>
                )
              }
            </List>
          </Info>
        </TravelerDetailsFull>
      </TravelerInfo>
    </StyledSection>
  );
};

export default ReviewTravelerInfo;
