import * as React from 'react';

const AngleRightIcon = () => {
  return (
    <img
      style={{
        display: 'inline-block',
        margin: '0 auto',
      }}
      src={require('../../../assets/images/angle-right.png')}
      alt=""
    />
  );
};

export default AngleRightIcon;
