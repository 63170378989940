import * as React from 'react';
import Text from '../../Text/Text';
import { PriceItems } from '../CheckoutSidebar.styles';

interface Props {
  name: string;
  amount: string;
  AmountComponent?: React.ElementType;
  renderAdditional?: any;
}

const Section = (props: Props) => {
  const { name, amount, AmountComponent, renderAdditional } = props;

  const renderAmount = (Component: React.ElementType) => <Component>{amount}</Component>;

  return (
    <PriceItems>
      <Text variant="strong">{name}</Text>
      {renderAdditional}
      {renderAmount(AmountComponent || Text)}
    </PriceItems>
  );
};

export default Section;
