import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as React from 'react';
import { useState } from 'react';
import Collapsible from 'react-collapsible';
import Map from '../../components/Map/Map';
import { ITrip } from '../../generated/graphql';
import { getBestTripSKUPrice, getDaysLeft, getSeatsLeft, isSoldOut } from '../../lib/trip';
import { findSelectedTrip, getSeatsLeftText, tripToKey } from '../../lib/utils';
import {
  Accordions,
  Dates,
  DateTextDesktop,
  DateTextMobile,
  DaysLeft,
  Price,
  Row,
  StyledText,
} from './SelectDateAccordions.styles';

interface Props {
  data: Array<ITrip>;
  value?: string;

  tripDateFormatDesktop?: string;
  tripDateFormatMobile?: string;

  onChange?(trip: ITrip): void;
}

const sortTripsByStartDate = (trips: ReadonlyArray<ITrip>) => R.sortBy((trip) => trip.startDate!, trips);

const sortTripPairs = (pairs: ReadonlyArray<[string, ReadonlyArray<ITrip>]>) => R.map(([key, trips]) => [key, sortTripsByStartDate(trips)], pairs);

function SelectDateAccordions(props: Props) {
  const { data, value, tripDateFormatDesktop = 'DDD (ccc)', tripDateFormatMobile = 'LL/dd/yyyy', onChange } = props;
  const trip = findSelectedTrip(data, value);
  const [open, setOpen] = useState(trip ? tripToKey(trip) : tripToKey(data[0]));

  const groupedTrips = R.pipe(
    R.groupBy(tripToKey),
    R.toPairs,
    sortTripPairs,
  )(data);

  return (
    <Accordions data-cy="select-date-accordions">
      <Map
        data={groupedTrips}
        render={([key, trips]: [string, Array<ITrip>]) => (
          <Collapsible
            key={key}
            open={open === key}
            onOpening={() => setOpen(key)}
            trigger={DateTime.fromFormat(key, 'yyyyMM').toFormat('LLLL yyyy')}
          >
            <Map
              data={trips}
              render={(trip: ITrip) => (
                <Row
                  data-cy="trip-row"
                  key={trip.tripId!}
                  onClick={() => onChange && onChange(trip)}
                  className={(value === trip.tripId ? 'active' : '') + '' + (isSoldOut(trip) ? 'soldout' : '')}
                >
                  <Dates data-cy="dates">
                    <DateTextDesktop>
                      {DateTime.fromISO(trip.startDate!).toFormat(tripDateFormatDesktop)} -{' '}
                      {DateTime.fromISO(trip.endDate!).toFormat(tripDateFormatDesktop)}
                    </DateTextDesktop>

                    <DateTextMobile>
                      {DateTime.fromISO(trip.startDate!).toFormat(tripDateFormatMobile)} -{' '}
                      {DateTime.fromISO(trip.endDate!).toFormat(tripDateFormatMobile)}
                    </DateTextMobile>
                  </Dates>

                  <section style={{ color: 'red' }}>{
                    R.pipe<any, any, any>(
                      R.defaultTo([]),
                      R.join(', ')
                    )(trip.tags)
                  }</section>

                  <DaysLeft data-cy="days">
                    <span>{getDaysLeft(trip)}</span>
                  </DaysLeft>

                  <StyledText
                    dataCy="seats-left"
                    weight="bold"
                    className="seatsLeft"
                    text={getSeatsLeftText(trip, getSeatsLeft(trip))}
                  />

                  <Price dataCy="price" weight="bold">
                    {getBestTripSKUPrice(trip)}
                  </Price>
                </Row>
              )}
            />
          </Collapsible>
        )}
      />
    </Accordions>
  );
}

export default SelectDateAccordions;
