import styled from 'styled-components';
import { TabletV } from '../Breakpoints/Breakpoints';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #eef2ec;
  border: 1px solid #e3dfdf;
  padding: 1rem;
  //max-width: 10.875rem;
  min-height: 8rem;
  margin-right: 1rem;
  text-align: center;
  cursor: pointer;
  //width: 275px;

  &:hover,
  &.active {
    background: #334d2b;
    label,
    p {
      color: #fff;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @media (${TabletV}) {
    min-height: 10.625rem;
  }
`;

export const IMG = styled.img`
  padding-bottom: 1.375rem;
`;

export const Label = styled.label`
  font-size: 1rem;
  color: #2f4427;
  padding-bottom: 0.375rem;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  line-height: 1.3;

  @media (${TabletV}) {
    font-size: 1.25rem;
  }
`;

export const Text = styled.p`
  font-size: 0.75rem;
  color: #6b6b6b;
  margin: 0;
  font-family: 'Raleway', sans-serif;
  line-height: 1.5;

  @media (${TabletV}) {
    font-size: 0.9375rem;
  }
`;
