import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';
import { createRootReducer, rootEpic } from '../redux';

const configureStore = ({ history }: { history: History }) => {
  const reducer = createRootReducer(history);
  const epicMiddleware = createEpicMiddleware();
  const middlewares = [epicMiddleware, routerMiddleware(history), thunk];
  const store = createStore(reducer, {}, composeWithDevTools(applyMiddleware(...middlewares)));

  epicMiddleware.run(rootEpic);

  return { store };
};

export default configureStore;
