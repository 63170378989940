import { DateTime } from 'luxon';
import * as R from 'ramda';
import { IOrderPreview, ITrip } from '../generated/graphql';
import config from './config';
import currencyFormatter from './currencyFormatter';

export function getBestTripSKU(trip: Partial<ITrip>) {
  let best = R.find(R.propEq('type', 'full'), trip.skus!);
  const { days } = DateTime.fromISO(trip.startDate!).diffNow('days');
  if (days >= config.earlyPriceDaysLimit) {
    best = R.find(R.propEq('type', 'early'), trip.skus!);
  }
  return best;
}

export function getBestTripSKUPrice(trip: ITrip) {
  const tripSKU = getBestTripSKU(trip);
  if (!tripSKU) {
    throw new Error('tripSKU undefined');
  }
  return currencyFormatter.format(tripSKU.price! / 100);
}

export function getDaysLeft(trip: ITrip) {
  return `${DateTime.fromISO(trip.endDate!).diff(DateTime.fromISO(trip.startDate!), 'days').days + 1} days`;
}

export function getSeatsLeft(trip: Partial<ITrip>) {
  return trip.maxSeats! - trip.seatsTaken!;
}

export function isSoldOut(trip: Partial<ITrip>) {
  return getSeatsLeft(trip) <= 0 || hasStarted(trip);
}

export function hasStarted(trip: Partial<ITrip>) {
  return DateTime.fromISO(trip.startDate!).diffNow('days').days <= 0;
}

export function getSKUFromOrderPreview(orderPreview: IOrderPreview) {
  return R.find(R.propEq('type', 'sku'))(orderPreview.items!);
}

export function listDiscountsFromOrderPreview(orderPreview: IOrderPreview) {
  return R.filter(R.propEq('type', 'discount'))(orderPreview.items!);
}

export function hasDiscounts(orderPreview: IOrderPreview) {
  const discounts = listDiscountsFromOrderPreview(orderPreview);
  return discounts.length > 0;
}
