import * as React from 'react';
import { useEffect } from 'react';
import Collapsible from 'react-collapsible';
import { useDispatch, useSelector } from 'react-redux';
import { CheckoutSidebar, ContactCard, LoadingSpinner, PolicyContext, ProgressBar } from '../../components';
import { useGetLocationQuery } from '../../generated/graphql';
import config from '../../lib/config';
import { goToStep } from '../../redux/checkoutStep';
import { Store } from '../../types';
import NotFound from '../NotFound/NotFound';
import { CheckoutGrid } from './Checkout.styles';
import AdditionalDetailsStep from './Steps/AdditionalDetailsStep';
import Location from './Steps/Location/Location';
import PaymentStep from './Steps/PaymentStep';
import TravelerInformationStep from './Steps/TravelerInformationStep';

interface Props {
  locationId: string;
}

const Checkout = (props: Props) => {
  const { locationId } = props;
  const { loading, error, data } = useGetLocationQuery({ variables: { locationId } });
  const { step } = useSelector((state: Store) => state.checkoutStep);
  const { sku } = useSelector((state: Store) => state.createBookingInput);
  const dispatch = useDispatch();

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${config.klaviyoKey}`;
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error && error.message === 'GraphQL error: 5 NOT_FOUND: Location not found') {
    return <NotFound text="This location does not exist" />;
  }

  if (!data) {
    return <NotFound text="This location does not exist" />;
  }

  const { getLocation: location, policy } = data;

  if (!location) {
    return <NotFound text="This location does not exist" />;
  }

  if (!location.trips!.length) {
    return <NotFound text="There are no trips available at this current time" />;
  }

  const renderStep = (step: number) => {
    switch (step) {
      case 0:
        return <Location location={location} />;
      case 1:
        return <TravelerInformationStep />;
      case 2:
        return <AdditionalDetailsStep />;
      case 3:
        return <PaymentStep location={location} />;
    }
  };

  return (
    <PolicyContext.Provider value={policy}>
      <CheckoutGrid data-cy="checkoutArea">
        {renderStep(step)}
        {!!step && (
          <>
            <ProgressBar
              style={{
                gridArea: 'progressBar'
              }}
              data={[
                {
                  name: {
                    desktop: 'Calendar',
                    tablet: 'Cal'
                  }
                },
                {
                  name: {
                    desktop: 'Traveler Information',
                    tablet: 'Traveler'
                  }
                },
                {
                  name: {
                    desktop: 'Additional Details',
                    tablet: 'Info'
                  }
                },
                {
                  name: {
                    desktop: 'Payment Method',
                    tablet: 'Payment'
                  }
                }
              ]}
              currentStep={step}
              onClick={(d: any, i: number) => {
                if (sku) {
                  dispatch(goToStep(i));
                }
              }}
            />

            <Collapsible
              style={{
                gridArea: 'sidebar'
              }}
              classParentString="Collapsible-Checkout"
              trigger={location.title!}
              open={true}
            >
              <CheckoutSidebar location={location} />
            </Collapsible>

            <ContactCard />
          </>
        )}
      </CheckoutGrid>
    </PolicyContext.Provider>
  );
};

export default Checkout;
