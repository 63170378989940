import styled from 'styled-components';
import { Desktop, Laptop, TabletH, TabletV } from '../../components/Breakpoints/Breakpoints';
import Text from '../../components/Text/Text';
import { Title6 } from '../../components/Title/Title';

export const CheckoutGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'sidebar' 
    'progressBar'
    'form'
    'contact';
  grid-gap: 1rem;
   
  padding: 0 1rem;
  margin: 0 auto;
  
  width: 100%;
  max-width: 96.875rem; 
  
  @media (${Laptop}) {
    grid-template-columns: 1fr minmax(10vw, 39rem);
    grid-template-areas:
      'progressBar progressBar' 
      'form sidebar'
      'form contact'
      'form .';
      
    padding: 3rem;
  }
`;

export const LeftContSection = styled('section')`
  display: grid;
  grid-gap: 1rem;
  background: #fff;
  padding: 1.5rem 1.25rem;

  @media (${TabletV}) {
    padding: 2rem 1.625rem;
  }
  
  @media (${TabletH}) {
    padding: 2.75rem 2.3785rem;
  }
  
  @media (${Laptop}) {
    padding: 3rem 2.5rem;
  }
  
  @media (${Desktop}) {
    padding: 3.375rem 2.8125rem;
  }
`;

export const InfoSectionPaddingTop = styled('section')`
  padding-top: 0.5rem;

  @media (${TabletV}) {
    padding-top: 1.25rem;
  }
`;

export const RadioStyledBoxes = styled('section')`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
`;

export const ContinueSection = styled.div`
  display: none;
  align-items: center;
  padding-top: 1rem;

  @media (${TabletH}) {
    justify-content: flex-end;
  }

  @media (${Laptop}) {
    display: flex;
  }

  .laddaButton {
    border: none;
    position: relative;

    .ladda-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #659254;
    }
  }
`;

export const PurchaseSection = styled(ContinueSection)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  button {
    max-width: none;
    width: 100%;
  }

  @media (${TabletH}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      max-width: 14rem;
      width: auto;
    }
  }
`;

export const PaymentDetails = styled.div`
  padding-bottom: 1rem;

  @media (${TabletH}) {
    padding-bottom: 0;
    padding-right: 3rem;
  }
`;

export const TermsTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    padding-bottom: 1rem;
  }
`;

export const PoliciesTextArea = styled('section')`
  p,
  li {
    color: #6b6b6b;
    padding-bottom: 1rem;
  }
`;

export const FriendName = styled.div`
  padding-top: 0.5rem;

  @media (${Laptop}) {
    padding-top: 1rem;
  }
`;

export const MobilePrice = styled.div`
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  padding: 0.625rem 1rem;
  padding-bottom: 4rem;
  z-index: 99;

  @media (${Laptop}) {
    display: none;
  }

  > div {
    padding-left: 0;
    justify-content: space-between;
  }
`;

export const MobilePriceText = styled(Title6)`
  font-size: 0.875em;
`;

export const PaymentLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PaymentError = styled(Text)`
  padding-top: 0.75rem;
  text-align: right;
  color: red;
`;
