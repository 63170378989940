import * as React from 'react';
import { Text, Title } from '../../components';
import { Container } from './NotFound.styles';

interface Props {
  text: string;
}

const NotFound = (props: Props) => (
  <Container>
    <Title>
      {props.text}
    </Title>

    <Text>
      <a href="https://www.under30experiences.com/trips">Checkout our destinations</a>
    </Text>
  </Container>
);

export default NotFound;
