import * as React from 'react';
import { useContext } from 'react';
import { Elements, injectStripe, ReactStripeElements } from 'react-stripe-elements';

export const StripeContext = React.createContext<{ stripe?: ReactStripeElements.StripeProps }>({ stripe: undefined });

export function StripeHookProvider({ children }: any) {
  const stripeData = {
    stripe: null, // This must be set inside the injected component
  };
  return (
    <Elements>
      <StripeInjectedWrapper stripeData={stripeData}>{children}</StripeInjectedWrapper>
    </Elements>
  );
}

function StripeHookProviderInnerWrapper({ stripe, stripeData, children }: any) {
  const updatedStripeData = { ...stripeData, stripe };
  return <StripeContext.Provider value={updatedStripeData}>{children}</StripeContext.Provider>;
}

const StripeInjectedWrapper = injectStripe(StripeHookProviderInnerWrapper);

export function useStripe() {
  return useContext(StripeContext);
}
