import * as React from 'react';
import { Item, StyledSection, StyledTitle4, StyledTitle5 } from './ContactCard.styles';

function ContactCard() {
  const phone = '1-888-839-2086';
  const email = 'bookings@under30experiences.com';
  return (
    <StyledSection
      style={{
        gridArea: 'contact'
      }}
    >
      <StyledTitle4 weight="bold">Need Help?</StyledTitle4>
      <Item>
        <img alt="phone" src={require('../../assets/images/phone.png')} />
        <StyledTitle5>
          <a href={`tel:${phone}`}>{phone}</a>
        </StyledTitle5>
      </Item>
      <Item>
        <img alt="email" src={require('../../assets/images/envelope.png')} />
        <StyledTitle5>
          <a href={`mailto:${email}`}>{email}</a>
        </StyledTitle5>
      </Item>
    </StyledSection>
  );
}

export default ContactCard;
