const config = {
  appVersion: process.env.REACT_APP_VERSION ?? '',
  apiURL: process.env.REACT_APP_API_URL ?? '',
  stripeAPIKey: process.env.REACT_APP_STRIPE_API_KEY ?? '',
  u30xReceiptUrl: process.env.REACT_APP_RECEIPT_URL ?? '',
  earlyPriceDaysLimit: 60,
  bugsnagAPIKey: process.env.REACT_APP_BUGSNAG_API_KEY ?? '',
  bugsnagReleaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE ?? '',
  klaviyoKey: process.env.REACT_APP_KLAVIYO_KEY ?? '',
};

export default config;
