import { Form } from 'formik';
import styled from 'styled-components';
import * as breakpoints from '../../../../components/Breakpoints/Breakpoints';
import Text from '../../../../components/Text/Text';
import { Title } from '../../../../components/Title/Title';

export const StyledText = styled(Text)`
  padding-bottom: 1rem;

  @media (${breakpoints.TabletV}) {
    padding-bottom: 1.125rem;
  }

  @media (${breakpoints.TabletH}) {
    padding-bottom: 1.25rem;
  }

  @media (${breakpoints.Laptop}) {
    padding-bottom: 1.5rem;
  }
`;

export const FormLayout = styled(Form)`
  grid-column: 1 / 3;
  background-color: #fff;

  padding: 1.25rem 1.5rem 1rem;

  @media (${breakpoints.TabletV}) {
    padding: 1.75rem 1.875rem 1.25rem;
  }

  @media (${breakpoints.TabletH}) {
    padding: 2.125rem 2.25rem 1.625rem;
  }

  @media (${breakpoints.Laptop}) {
    padding: 2.5rem 2.8125rem 2rem;
  }
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  
  padding: 1.25rem 1.5rem;
  margin-top: 2rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  @media (${breakpoints.TabletV}) {
    padding: 1.75rem 1.875rem;
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }

  @media (${breakpoints.TabletH}) {
    flex-direction: row;
    padding: 2.125rem 2.25rem;
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  @media (${breakpoints.Laptop}) {
    padding: 2.5rem 2.8125rem;
    margin-left: -2.8125rem;
    margin-right: -2.8125rem;
  }
`;

export const SummaryLeft = styled.div`
  max-width: 34.375rem;
  padding-bottom: 1.5rem;

  @media (${breakpoints.TabletH}) {
    padding-bottom: 0;
  }
`;

export const SummaryRight = styled.div`
  @media (${breakpoints.TabletH}) {
    padding-left: 6.25rem;
  }
`;

export const SummaryText = styled(Text)`
  color: #6b6b6b;

  .cancellation {
    color: #294f14;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const PromoForm = styled.form`
  display: flex;

  div {
    display: flex;
    flex: 1;
  }

  input {
    flex: 1;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .laddaButton {
    border: none;
    position: relative;
    padding: 0;
    width: 8.3125rem;

    .ladda-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #659254;
    }
  }

  @media (${breakpoints.TabletV}) {
    button {
      padding: 1rem 2.5em;
    }

    .laddaButton {
      width: 8.3125rem;
    }
  }

  @media (${breakpoints.Laptop}) {
    button {
      padding: 1.25rem 2.5em;
    }
  }
`;

export const PromoInvalidResponse = styled.p`
  font-family: 'Raleway', sans-serif;
  padding-top: 1rem;
  color: red;
  text-align: left;
`;

export const Days = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2.5rem;
  &:last-child {
    padding-bottom: 0;
  }

  &.includes {
    align-items: flex-start;
  }

  @media (${breakpoints.TabletV}) {
    justify-content: flex-start;
  }
`;

export const DayItem = styled.div`
  width: 9rem;

  @media (${breakpoints.TabletV}) {
    width: auto;
  }

  @media (${breakpoints.TabletH}) {
    width: 9rem;
  }

  > p {
    padding-bottom: 0.5rem;
  }
`;

export const SpaceBreak = styled.div`
  width: 3.75rem;

  @media (${breakpoints.TabletV}) {
    width: 9.375rem;
    flex: 1;
  }

  @media (${breakpoints.Laptop}) {
    flex: initial;
  }

  &.invisible {
    * {
      visibility: hidden;
    }
  }
`;

export const AngleRight = styled.img`
  display: block;
  margin: 0 auto;
`;

export const TotalTravelers = styled(Text)`
  color: #000000;
  align-self: center;
  font-size: 1rem;

  @media (${breakpoints.TabletV}) {
    font-size: 1.25rem;
  }
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 2rem;

  @media (${breakpoints.TabletV}) {
    padding-left: 0;
    flex: 1;
  }
`;

export const SelectDatesHeader = styled(Title)`
  // padding-bottom: 1.5rem;
  // text-align: center;
  //
  // @media (${breakpoints.TabletV}) {
  //   padding-bottom: 2.25rem;
  // }
  //
  // @media (${breakpoints.TabletH}) {
  //   padding-bottom: 2.75rem;
  // }
  //
  // @media (${breakpoints.Laptop}) {
  //   padding-bottom: 3.125rem;
  // }
`;

export const LocationStyledText = styled(Text)`
  padding-bottom: 1rem;

  @media (${breakpoints.TabletV}) {
    padding-bottom: 1.125rem;
  }

  @media (${breakpoints.TabletH}) {
    padding-bottom: 1.25rem;
  }

  @media (${breakpoints.Laptop}) {
    padding-bottom: 1.5rem;
  }
`;
