import styled from 'styled-components';
import { Laptop, TabletV } from '../Breakpoints/Breakpoints';

export const StyleRadio = styled.div`
  display: inline-block;
  min-width: 5rem;

  &:first-child {
    border-right: 1px solid #c3ccbf;
    label {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }
  }

  &:last-child {
    label {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }

  @media (${TabletV}) {
    min-width: 6rem;
  }

  @media (${Laptop}) {
    min-width: 7.75rem;
  }
`;

export const StyledLabel = styled.label`
  float: left;
  width: 100%;
  background-color: #eeeeee;
  cursor: pointer;
  position: relative;

  &.checked {
    background-color: #334d2b;
    span {
      color: #fff;
    }
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  display: none;
`;

export const StyledSpan = styled.span`
  border-radius: 0;
  color: #659254;
  text-align: center;
  font-size: 0.75em;
  padding: 0.75rem 1rem;
  display: block;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;

  @media (${TabletV}) {
    font-size: 0.875em;
    padding: 0.875rem 1.25rem;
  }

  @media (${Laptop}) {
    font-size: 1.25em;
    padding: 1rem 2rem;
  }
`;
