import bugsnagReact from '@bugsnag/plugin-react';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { StripeProvider } from 'react-stripe-elements';
import { ThemeProvider } from 'styled-components';
import App from './App';
import { SessionProvider, StripeHookProvider } from './components';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import './index.css';
import apolloClient from './lib/apolloClient';
import bugsnagClient from './lib/bugsnagClient';
import config from './lib/config';
import configureStore from './lib/configureStore';
import registerServiceWorker from './registerServiceWorker';
import NotFound from './routes/NotFound/NotFound';
import theme from './theme';

bugsnagClient.use(bugsnagReact, React);

const history = createBrowserHistory();
const { store } = configureStore({ history });
const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
  <ErrorBoundary>
    <StripeProvider apiKey={config.stripeAPIKey}>
      <StripeHookProvider>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={apolloClient}>
            <Provider store={store as any}>
              <ConnectedRouter history={history}>
                <SessionProvider>
                  <main>
                    <section
                      style={{
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Navbar />
                      <Switch>
                        <Route exact={true} path="/:locationId" component={App} />
                        <Route render={() => <NotFound text="Page not found" />} />
                      </Switch>
                      <Footer />
                    </section>
                  </main>
                </SessionProvider>
              </ConnectedRouter>
            </Provider>
          </ApolloProvider>
        </ThemeProvider>
      </StripeHookProvider>
    </StripeProvider>
  </ErrorBoundary>,
  document.getElementById('root') as HTMLElement,
);

registerServiceWorker();
