import { ErrorMessage } from 'formik';
import * as React from 'react';
import { InputGroup } from '..';
import Radio from '../../components/Radio/Radio';
import RadioGroup from '../../components/RadioGroup/RadioGroup';

interface Props {
  name: string;
  value?: any;

  onChange?(e: any): void;
}

const ReturnCustomerRadioGroup = (props: Props) => {
  const { name, value, onChange } = props;

  return (
    <InputGroup data-cy="returnCustomer">
      <RadioGroup>
        <Radio
          dataCy="returnCustomerYes"
          label="Yes"
          name={name}
          value={true}
          checked={value}
          onChange={onChange}
        />
        <Radio
          dataCy="returnCustomerNo"
          label="No"
          name={name}
          value={false}
          checked={value}
          onChange={onChange}
        />
      </RadioGroup>
      <ErrorMessage
        name={name}
        render={message => <p style={{ color: 'red', marginTop: '1rem' }}>{message}</p>}
      />
    </InputGroup>
  );
};

export default ReturnCustomerRadioGroup;
