import 'rxjs/add/operator/mergeMap';
import { Action } from 'typescript-fsa';

// Initial State

export interface State {
  step: number;
}

export const INITIAL_STATE: State = {
  step: 0
};

// Constants

export const GO_TO_STEP = 'GO_TO_STEP';

export const CLEAR_CHECKOUT_STEP = 'CLEAR_CHECKOUT_STEP';

// Reducers

export default function reducer(state: State = INITIAL_STATE, action: Action<any>) {
  switch (action.type) {
    case GO_TO_STEP:
      return {
        ...state,
        step: action.payload
      };
    case CLEAR_CHECKOUT_STEP:
      return INITIAL_STATE;
    default:
      return state;
  }
}

// Actions

export const goToStep = (payload: number) => ({
  type: GO_TO_STEP,
  payload
});

export const clearCheckoutStep = () => ({
  type: CLEAR_CHECKOUT_STEP
});
